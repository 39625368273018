import React, { useEffect, useState } from "react";
import { API } from "aws-amplify";
import { Table, Spinner, Button, Row, Col } from "react-bootstrap";

const apiName = "talentsapi";
const fetchPath = "/viewStagingFiles";
const approvePath = "/approveStagingFiles"; // Replace with your actual path
const deletePath = "/deleteStagingFiles"; // Replace with your actual path

const ViewStagingFiles = () => {
  const [stagingFiles, setStagingFiles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedFiles, setSelectedFiles] = useState([]);

  useEffect(() => {
    const fetchStagingFiles = async () => {
      try {
        const response = await API.get(apiName, fetchPath, {});
        console.log("Staging files response:", response);

        // Clean the file paths
        const files = response.objects;
        // .filter((filePath) => filePath !== "public/staging/") // Exclude the folder itself
        // .map((filePath) => filePath.replace("public/staging/", "")); // Remove folder structure

        setStagingFiles(files);
      } catch (error) {
        console.error("Error fetching staging files:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchStagingFiles();
  }, []);

  const handleSelectAll = (isChecked) => {
    setSelectedFiles(isChecked ? [...stagingFiles] : []);
  };

  const handleSelectFile = (fileName) => {
    setSelectedFiles(
      (prevSelected) =>
        prevSelected.includes(fileName)
          ? prevSelected.filter((file) => file !== fileName) // Deselect
          : [...prevSelected, fileName] // Select
    );
  };

  const handleApproveClick = async () => {
    if (selectedFiles.length === 0) {
      alert("Please select files to approve.");
      return;
    }

    try {
      const requestBody = { fileNames: selectedFiles };

      const response = await API.post(apiName, approvePath, {
        body: requestBody,
      });

      console.log("Approve response:", response);
      alert("Selected files approved successfully.");

      // Refresh the file list after approval
      const updatedFiles = stagingFiles.filter(
        (file) => !selectedFiles.includes(file)
      );
      setStagingFiles(updatedFiles);
      setSelectedFiles([]);
    } catch (error) {
      console.error("Error approving files:", error);
      alert("Failed to approve files. Please try again.");
    }
  };

  const handleDeleteSelected = async () => {
    if (selectedFiles.length === 0) {
      alert("Please select files to delete.");
      return;
    }

    try {
      const requestBody = { fileNames: selectedFiles };

      const response = await API.post(apiName, deletePath, {
        body: requestBody,
      });

      console.log("Delete response:", response);
      alert("Selected files deleted successfully.");

      // Refresh the file list after deletion
      const updatedFiles = stagingFiles.filter(
        (file) => !selectedFiles.includes(file)
      );
      setStagingFiles(updatedFiles);
      setSelectedFiles([]);
    } catch (error) {
      console.error("Error deleting files:", error);
      alert("Failed to delete files. Please try again.");
    }
  };

  return (
    <div>
      <h4>Staging Files ({stagingFiles.length})</h4>
      {loading ? (
        <Spinner animation="border" />
      ) : (
        <>
          {stagingFiles.length > 0 ? (
            <>
              <Row className="mt-3">
                <Col style={{ textAlign: "center" }}>
                  <Button variant="success" onClick={handleApproveClick}>
                    Approve
                  </Button>
                </Col>
                <Col>
                  <Button
                    variant="danger"
                    onClick={handleDeleteSelected}
                    // disabled={selectedFiles.length === 0}
                    className="mb-3"
                  >
                    Delete Selected Files
                  </Button>
                </Col>
              </Row>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>
                      <input
                        type="checkbox"
                        onChange={(e) => handleSelectAll(e.target.checked)}
                        checked={
                          selectedFiles.length > 0 &&
                          selectedFiles.length === stagingFiles.length
                        }
                      />
                    </th>
                    <th>File Name</th>
                  </tr>
                </thead>
                <tbody>
                  {stagingFiles.map((file, index) => (
                    <tr key={index}>
                      <td>
                        <input
                          type="checkbox"
                          onChange={() => handleSelectFile(file)}
                          checked={selectedFiles.includes(file)}
                        />
                      </td>
                      <td>{file}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </>
          ) : (
            <p>No files found in the staging folder.</p>
          )}
        </>
      )}
    </div>
  );
};

export default ViewStagingFiles;
