import React from "react";

const CasFSTemplate = ({ date }) => {
  return (
    <div>
      <p>
        <span style={{ fontWeight: "bold", textDecoration: "underline" }}>
          {date} Capital Account Statements and Fund Financials
        </span>{" "}
        for each of our 10Talents Investors managed funds are available for
        review via our secure investor portal via the link listed below.
      </p>
      <p>You will need your user name and password to gain access.</p>
    </div>
  );
};

export default CasFSTemplate;
