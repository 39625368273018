import React from "react";
import { Authenticator } from "@aws-amplify/ui-react";
import "./LoginPage.css"; // Import your CSS for styling

// Define custom formFields
const formFields = {
  confirmSignIn: {
    confirmation_code: {
      label: "Enter TOTP Code",
      placeholder: "Enter your 6-digit code",
      isRequired: true,
      autoFocus: true, // Automatically place the cursor in this field
    },
  },
};

// Define custom components (e.g., header)
const components = {
  Header() {
    return (
      <div className="auth-header">
        <h1>10Talents Portal</h1>
      </div>
    );
  },
};

function LoginPage() {
  const changeOfState = () => {
    console.log("the state hath changed milord");
  };

  return (
    <div className="login-container">
      <Authenticator
        formFields={formFields}
        components={components}
        hideSignUp
        onSubmit={changeOfState}
      />
    </div>
  );
}

export default LoginPage;
