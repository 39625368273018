import React, { useEffect, useState } from "react";
import { API } from "aws-amplify";
import GenericTable from "../CRUD Tables/GenericTable"; // Assuming GenericTable is in the same directory
import "bootstrap/dist/css/bootstrap.min.css";

const apiName = "talentsapi";

const fetchPresignedUrls = async (
  token,
  fund,
  doc_type,
  startDate,
  endDate,
  serialIDArray, // Expect serialIDArray to be an array of objects
  setPresignedUrls,
  setLoading
) => {
  if (token) {
    setLoading(true);
    const path = "/presignedUrls";
    const requestInfo = {
      headers: {
        Authorization: token,
      },
      queryStringParameters: {},
    };

    if (fund) {
      requestInfo.queryStringParameters.Fund = fund;
    }

    if (doc_type) {
      requestInfo.queryStringParameters.DocType = doc_type;
    }

    if (serialIDArray && Array.isArray(serialIDArray)) {
      // Loop through the serialIDArray to extract serialID from each object
      serialIDArray.forEach((item) => {
        if (item.serialID) {
          // Add each serialID to the queryStringParameters, using the same key 'serialID[]'
          if (!requestInfo.queryStringParameters.serialID) {
            requestInfo.queryStringParameters.serialID = [];
          }
          requestInfo.queryStringParameters.serialID.push(item.serialID);
        }
      });
    }

    // Join serialID array into a string, separated by commas
    if (requestInfo.queryStringParameters.serialID) {
      requestInfo.queryStringParameters.serialID =
        requestInfo.queryStringParameters.serialID.join(",");
    }

    const convertToISODate = (date) => date.toISOString().split("T")[0];

    if (startDate) {
      requestInfo.queryStringParameters.startDate = convertToISODate(startDate);
    }

    if (endDate) {
      requestInfo.queryStringParameters.endDate = convertToISODate(endDate);
    }

    try {
      const response = await API.get(apiName, path, requestInfo);
      setPresignedUrls(response.presignedUrls || []); // Ensure it's an empty array if no files
    } catch (error) {
      console.error("Error fetching presigned URLs:", error);
    } finally {
      setLoading(false);
    }
  }
};

const PresignedUrlList2 = ({
  token,
  fund,
  doc_type,
  startDate,
  endDate,
  serialIDArray, // Expect an array of objects with serialID properties
}) => {
  const [presignedUrls, setPresignedUrls] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (token) {
      fetchPresignedUrls(
        token,
        fund,
        doc_type,
        startDate,
        endDate,
        serialIDArray, // Pass serialIDArray to the fetch function
        setPresignedUrls,
        setLoading
      );
    }
  }, [token, fund, doc_type, startDate, endDate, serialIDArray]); // Add serialIDArray to the dependency array

  console.log("presignedURLs", presignedUrls);

  // Compute headers after presignedUrls are set
  const headers = presignedUrls.length > 0 ? Object.keys(presignedUrls[0]) : [];

  // Define the columns you want to be visible
  const visibleColumns = [
    "serialID",
    "fund",
    "doc_type",
    "report_name",
    "investor_name",
    "presignedUrl",
  ];

  // Define which columns should be filterable
  const filterableColumns = [
    "fund",
    "doc_type",
    "report_name",
    "investor_name",
  ]; // Exclude presignedUrl from filters

  // Define the state of each action
  const actionStates = {
    download: true, // Allow download if URLs exist
    create: false, // Allow creation
    delete: true, // Disable delete
    update: false, // Allow update
  };

  return (
    <div>
      <h1 style={{ color: "var(--text-color)" }}>Documents</h1>
      <GenericTable
        data={presignedUrls}
        loading={loading}
        headers={headers}
        keyField="filepath"
        visibleColumns={visibleColumns}
        filterableColumns={filterableColumns}
        actionStates={actionStates}
        serialID={serialIDArray}
        dropdownContext="file" // Identifier for file context
      />
    </div>
  );
};

export default PresignedUrlList2;
