import React, { useState, useEffect } from "react";

const DropdownFilter = ({ options, onSelect, placeholder, value }) => {
  const [selectedOption, setSelectedOption] = useState(value || "");

  // Update the internal state when the parent `value` changes
  useEffect(() => {
    setSelectedOption(value || "");
  }, [value]);

  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);
    onSelect(selectedValue);
  };

  return (
    <div>
      <select
        id="filterDropdown"
        value={selectedOption}
        onChange={handleSelectChange}
        className="filter-dropdown"
      >
        <option value="">{placeholder}</option>
        {options.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
  );
};

export default DropdownFilter;
