import React, { useState, useEffect } from "react";
import { API, graphqlOperation } from "aws-amplify";
import CreateModal from "./CreateModal"; // Import CreateModal

import {
  Table,
  Row,
  Col,
  Modal,
  Form,
  Button,
  Dropdown,
  DropdownButton,
} from "react-bootstrap";
import {
  updateInvestor,
  updateAccount,
  updateContact,
  updateDocPackageName,
  updateDocPackagePermission,
  updateOpportunity,
  updateFeederFund,
  deleteInvestor,
  deleteAccount,
  deleteContact,
  deleteDocPackageName,
  deleteDocPackagePermission,
  deleteOpportunity,
  deleteFeederFund,
} from "../../graphql/mutations";
import {
  // listFeederFunds,
  listContacts,
  // listAccounts,
  listInvestors,
  listOpportunities,
  // listDocPackageNames,
  listDocPackagePermissions,
} from "../../graphql/queries";

const GenericTable = ({
  data,
  headers,
  loading,
  keyField,
  visibleColumns,
  selectedType,
  columnsMap,
}) => {
  const [sortedData, setSortedData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editRecord, setEditRecord] = useState({});
  const [refreshData, setRefreshData] = useState(false);

  useEffect(() => {
    if (data && data.length > 0) {
      setSortedData(data);
    }
  }, [data]);

  // console.log("tableData:", data);
  // console.log("tablesortedData:", sortedData);
  console.log("editRecord", editRecord);

  const getUpdateMutation = (type) => {
    switch (type) {
      case "Feeder Funds":
        return updateFeederFund;
      case "Contacts":
        return updateContact;
      case "Accounts":
        return updateAccount;
      case "Investors":
        return updateInvestor;
      case "Opportunities":
        return updateOpportunity;
      case "Doc. Packages":
        return updateDocPackageName;
      case "Doc. Package Permissions":
        return updateDocPackagePermission;
      default:
        console.error("Unknown type for update:", type);
        return null;
    }
  };

  const getDeleteMutation = (type) => {
    switch (type) {
      case "Feeder Funds":
        return deleteFeederFund;
      case "Contacts":
        return deleteContact;
      case "Accounts":
        return deleteAccount;
      case "Investors":
        return deleteInvestor;
      case "Opportunities":
        return deleteOpportunity;
      case "Doc. Packages":
        return deleteDocPackageName;
      case "Doc. Package Permissions":
        return deleteDocPackagePermission;
      default:
        console.error("Unknown type for delete:", type);
        return null;
    }
  };

  const openEditModal = (record) => {
    setEditRecord(record);
    console.log("editRecord", editRecord);
    setIsModalOpen(true);
  };

  const handleSave = async (record) => {
    const mutation = editRecord;
    console.log("mutation", mutation);
    getUpdateMutation(selectedType); // Use the appropriate mutation
    if (!mutation) return;

    try {
      const input = {
        [keyField]: editRecord ? editRecord[keyField] : undefined, // Include keyField if editing, omit if creating
        ...Object.fromEntries(
          Object.entries(record).filter(([field]) =>
            visibleColumns.includes(field)
          )
        ),
      };
      console.log("input", input);
      await API.graphql(graphqlOperation(mutation, { input }));

      // Update sortedData with the new or updated record
      setSortedData((prevData) => {
        if (editRecord) {
          // Update existing record
          return prevData.map((row) =>
            row[keyField] === editRecord[keyField] ? { ...row, ...record } : row
          );
        } else {
          // Add new record
          return [...prevData, record];
        }
      });

      setIsModalOpen(false);
      setEditRecord(null); // Clear editRecord after save
    } catch (error) {
      console.error("Error saving record:", error);
      alert("Error saving record. Please try again.");
    }
  };

  const checkDeleteConditions = async (record) => {
    switch (selectedType) {
      case "Doc. Package Permissions":
        return true; // Can always be deleted

      case "Doc. Packages":
        const docPackagePermissions = await API.graphql(
          graphqlOperation(listDocPackagePermissions, {
            filter: { doc_package_name_id: { eq: record.id } },
          })
        );
        return (
          docPackagePermissions.data.listDocPackagePermissions.items.length ===
          0
        );

      case "Opportunities":
        const docPackagePermissionsForOpportunity = await API.graphql(
          graphqlOperation(listDocPackagePermissions, {
            opportunity_id: record.opportunity_id,
          })
        );
        return (
          docPackagePermissionsForOpportunity.data.listDocPackagePermissions
            .items.length === 0
        );

      case "Investors":
        const opportunitiesForInvestor = await API.graphql(
          graphqlOperation(listOpportunities, {
            filter: { investor_id: { eq: record.id } },
          })
        );
        return (
          opportunitiesForInvestor.data.listOpportunities.items.length === 0
        );

      case "Accounts":
        const investorsForAccount = await API.graphql(
          graphqlOperation(listInvestors, {
            filter: { account_id: { eq: record.id } },
          })
        );
        const contactsForAccount = await API.graphql(
          graphqlOperation(listContacts, {
            filter: { account_id: { eq: record.id } },
          })
        );
        return (
          investorsForAccount.data.listInvestors.items.length === 0 &&
          contactsForAccount.data.listContacts.items.length === 0
        );

      case "Contacts":
        const docPackagePermissionsForContact = await API.graphql(
          graphqlOperation(listDocPackagePermissions, {
            filter: { contact_id: { eq: record.id } },
          })
        );
        return (
          docPackagePermissionsForContact.data.listDocPackagePermissions.items
            .length === 0
        );

      case "Feeder Funds":
        const opportunitiesForFeederFund = await API.graphql(
          graphqlOperation(listOpportunities, {
            filter: { feeder_fund_id: { eq: record.id } },
          })
        );
        return (
          opportunitiesForFeederFund.data.listOpportunities.items.length === 0
        );

      default:
        return false;
    }
  };

  const handleDelete = async (record) => {
    const mutation = getDeleteMutation(selectedType);
    if (!mutation) return;

    try {
      const canDelete = await checkDeleteConditions(record);
      if (!canDelete) {
        alert("Error: Record cannot be deleted because it has dependencies.");
        return;
      }

      // Perform the delete operation if no dependencies exist
      await API.graphql(
        graphqlOperation(mutation, { input: { id: record[keyField] } })
      );
      setSortedData((prevData) =>
        prevData.filter((row) => row[keyField] !== record[keyField])
      );
      alert("Record deleted successfully.");
      setRefreshData(!refreshData); // Trigger data refresh
    } catch (error) {
      console.error("Error deleting record:", error);
      alert("Error deleting record. Please try again.");
    }
  };

  const renderRows = () => {
    return (
      <tbody>
        {sortedData.map((row) => (
          <tr key={row[keyField]}>
            {visibleColumns.map((header, colIndex) => (
              <td key={colIndex}>{row[header]}</td>
            ))}
            <td style={{ overflow: "visible" }}>
              <DropdownButton
                id="actions-dropdown"
                title=""
                variant="secondary"
                onClick={() => setEditRecord(row)}
              >
                <Dropdown.Item onClick={() => openEditModal(row)}>
                  Edit
                </Dropdown.Item>
                <Dropdown.Item onClick={() => handleDelete(row)}>
                  Delete
                </Dropdown.Item>
              </DropdownButton>
            </td>
          </tr>
        ))}
      </tbody>
    );
  };

  return (
    <div>
      <Row>
        <Col>
          <Table striped bordered hover variant="dark">
            <thead>
              <tr>
                {visibleColumns.map((header, index) => (
                  <th key={index}>{header}</th>
                ))}
                <th></th>
              </tr>
            </thead>
            {loading ? (
              <tbody>
                <tr>
                  <td>Loading...</td>
                </tr>
              </tbody>
            ) : (
              renderRows()
            )}
          </Table>
        </Col>
      </Row>

      {/* Edit Modal */}
      <CreateModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSave={handleSave}
        editRecord={editRecord} // Pass the record to edit if updating
        visibleColumns={visibleColumns} // Pass the visible columns
        keyField={keyField} // Pass the keyField (e.g., 'id')
        type={selectedType} // Pass the selected type for display in the modal title
        columnsMap={columnsMap} // Pass the columns
      />
    </div>
  );
};

export default GenericTable;
