const dummyData = [
  {
    id: 1,
    name: "InnovateTech",
    longName: "InnovateTech Solutions Inc.",
    sector: "Technology",
    industry: "Software",
    description:
      "InnovateTech Solutions Inc. is a trailblazer in the software industry, delivering state-of-the-art technology solutions to businesses worldwide. Known for its innovative products, the company has established itself as a leader in driving digital transformation.",
    memo: "InnovateTech IAC Memo",
    pastPerformance: [
      { name: "Fund 1", vintage: 2015, irr: "25%", moi: "2.5x" },
      { name: "Fund 2", vintage: 2016, irr: "18%", moi: "2.0x" },
      { name: "Fund 3", vintage: 2017, irr: "20%", moi: "2.2x" },
    ],
    icon: "FaApple",
  },
  {
    id: 2,
    name: "BioGenix",
    longName: "BioGenix Pharmaceuticals Corp.",
    sector: "Healthcare",
    industry: "Biotech",
    description:
      "BioGenix Pharmaceuticals Corp. is at the forefront of genetic research, pioneering new solutions in the biotech space. With a focus on innovation and improving human health, the company continues to lead advancements in pharmaceuticals and medical technology.",
    memo: "BioGenix IAC Memo",
    pastPerformance: [
      { name: "Fund 1", vintage: 2012, irr: "20%", moi: "1.8x" },
      { name: "Fund 3", vintage: 2014, irr: "22%", moi: "2.1x" },
      { name: "Fund 4", vintage: 2015, irr: "19%", moi: "2.0x" },
    ],
    icon: "FaDna",
  },
  {
    id: 3,
    name: "FinManage",
    longName: "FinManage Asset Management LLC",
    sector: "Finance",
    industry: "Asset Management",
    description:
      "FinManage Asset Management LLC specializes in managing alternative investments, offering tailored solutions to maximize returns. The firm is renowned for its expertise in navigating complex financial markets and delivering exceptional value to its clients.",
    memo: "FinManage IAC Memo",
    pastPerformance: [
      { name: "Fund 2", vintage: 2018, irr: "15%", moi: "1.6x" },
      { name: "Fund 4", vintage: 2019, irr: "17%", moi: "1.9x" },
      { name: "Fund 5", vintage: 2020, irr: "16%", moi: "1.7x" },
    ],
    icon: "FaChartLine",
  },
  {
    id: 4,
    name: "EcoRenew",
    longName: "EcoRenew Energy Solutions Ltd.",
    sector: "Energy",
    industry: "Renewables",
    description:
      "EcoRenew Energy Solutions Ltd. is a pioneer in renewable energy, focusing on harnessing solar and wind power. Committed to sustainability, the company develops innovative energy solutions that contribute to a greener and more sustainable future.",
    memo: "EcoRenew IAC Memo",
    pastPerformance: [
      { name: "Fund 3", vintage: 2010, irr: "30%", moi: "3.0x" },
      { name: "Fund 5", vintage: 2011, irr: "28%", moi: "2.8x" },
      { name: "Fund 6", vintage: 2012, irr: "29%", moi: "3.1x" },
    ],
    icon: "FaSolarPanel",
  },
  {
    id: 5,
    name: "GreenCart",
    longName: "GreenCart Retail Group",
    sector: "Consumer",
    industry: "Retail",
    description:
      "GreenCart Retail Group is a leader in sustainable retail, offering eco-friendly products and practices. With a strong commitment to environmental responsibility, the company has built a loyal customer base that values sustainability and ethical sourcing.",
    memo: "GreenCart IAC Memo",
    pastPerformance: [
      { name: "Fund 4", vintage: 2016, irr: "12%", moi: "1.4x" },
      { name: "Fund 6", vintage: 2017, irr: "14%", moi: "1.6x" },
      { name: "Fund 7", vintage: 2018, irr: "13%", moi: "1.5x" },
    ],
    icon: "FaShoppingCart",
  },
  {
    id: 6,
    name: "RoboFab",
    longName: "RoboFab Advanced Manufacturing Inc.",
    sector: "Industrial",
    industry: "Manufacturing",
    description:
      "RoboFab Advanced Manufacturing Inc. excels in robotics and advanced manufacturing technologies. The company is known for its cutting-edge solutions, enabling efficient and innovative production processes across various industries.",
    memo: "RoboFab IAC Memo",
    pastPerformance: [
      { name: "Fund 5", vintage: 2014, irr: "19%", moi: "2.2x" },
      { name: "Fund 6", vintage: 2015, irr: "21%", moi: "2.3x" },
      { name: "Fund 7", vintage: 2016, irr: "20%", moi: "2.1x" },
    ],
    icon: "FaCogs",
  },
];

export default dummyData;
