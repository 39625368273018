import React from "react";

const ReportingPackTemplate = ({ date }) => {
  return (
    <div>
      <p>
        The{" "}
        <span style={{ fontWeight: "bold", textDecoration: "underline" }}>
          10Talents {date} Quarterly LP Report
        </span>{" "}
        is available for review via our secure investor portal (see link below),
        under the heading "10Talents Private Equity Program." In this report you
        will find a holistic overview of your portfolio which includes
        commentaries on each fund along with their latest valuations and cash
        flows. We also wanted to provide you with an update on our diverse
        portfolios and how their strategies fit with the state of the current
        markets. We take a conservative view of things and want to share what is
        going well and the things that concern us. So, we start off with an
        overview of the current portfolio bucketed by sector and then transition
        to some concluding thoughts on the state of financial markets.
      </p>
      <p>[Insert portfolio and economic commentary here]</p>
    </div>
  );
};

export default ReportingPackTemplate;
