import { useEffect, useState } from "react";
import { Auth, Amplify, API } from "aws-amplify";
import { useNavigate } from "react-router-dom";
// import { generateClient } from "aws-amplify/api";
import config from "./aws-exports";
import { listUserInfo, listInvestorInfo } from "./graphql/queries";

Amplify.configure(config);

// const client = generateClient();

// const contactId = "c46854d8-6011-70f9-cec4-0e894a1a2ab2"; // Set your contact_id here

// const contacts = await API.graphql({
//   query: listContacts,
//   variables: {
//     contact_id: contactId,
//   },
// });

// console.log("Contacts: ", contacts);

// const accountId = "0e2a361b-aad8-4c9b-aa93-3372c6f9e631"; // Set your contact_id here

const useSessionChecker = () => {
  const [cognitoPayload, setCognitoPayload] = useState(null); // State to store payload
  const [userInfo, setUserInfo] = useState(null); // State to store user info
  const [investorsInfo, setInvestorsInfo] = useState(null); // State to store investors info

  const navigate = useNavigate();

  // add graphql query to ddb userTable to get other info, familiar name, permissions, etc.

  useEffect(() => {
    let intervalId;

    const checkAuthStatus = async () => {
      try {
        const session = await Auth.currentSession();
        const idToken = session.getIdToken();
        const currentPayload = idToken.payload;

        setCognitoPayload(currentPayload); // Update payload

        // Fetch user and investor info
        const userInfoResponse = await API.graphql({
          query: listUserInfo,
          variables: {
            contact_id: currentPayload.sub,
          },
        });

        const investorsResponse = await API.graphql({
          query: listInvestorInfo,
          variables: {
            account_id: userInfoResponse.data.listUserInfo[0].account_id,
          },
        });

        setUserInfo(userInfoResponse.data.listUserInfo);
        setInvestorsInfo(investorsResponse.data.listInvestorInfo);
      } catch (error) {
        console.log("Session expired or user not authenticated:", error);
        await Auth.signOut(); // Log out
        navigate("/"); // Redirect to login
      }
    };

    // console.log("userInfo:", userInfo);
    // Check immediately
    checkAuthStatus();

    // Set up periodic checks (e.g., every 5 minutes)
    intervalId = setInterval(checkAuthStatus, 5 * 60 * 1000);

    // Clean up on unmount
    return () => clearInterval(intervalId);
  }, [navigate]);

  return { cognitoPayload, userInfo, investorsInfo }; // Return the payload to be accessible by other components
};

export default useSessionChecker;
