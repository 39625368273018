import React from "react";

const ReminderTemplate = ({ date, fundLongName }) => {
  const dateStr = date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  return (
    <div>
      <p>
        <span style={{ fontWeight: "bold", textDecoration: "underline" }}>
          This is a friendly reminder
        </span>{" "}
        that your capital call for {fundLongName} is{" "}
        <span style={{ fontWeight: "bold" }}>due tomorrow, {dateStr}.</span>
      </p>
      <p>
        The Capital Call Notice is available for your review on our secure
        investor reporting portal via the Portal address listed below.
      </p>
    </div>
  );
};

export default ReminderTemplate;
