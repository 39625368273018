import React from "react";

const AYEFSTemplate = ({ date }) => {
  return (
    <div>
      <p>
        The{" "}
        <span style={{ fontWeight: "bold" }}>
          Audited Year End Financials as of {date}
        </span>{" "}
        for each of your 10Talents funds are now available for you to review on
        our secure investor reporting portal via the Portal address listed
        below. You will need your user name and password to gain access. If you
        have any trouble accessing this item or need your username or password
        reset, please contact us for personal assistance at any time.
      </p>
    </div>
  );
};

export default AYEFSTemplate;
