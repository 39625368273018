import React, { useEffect, useState } from "react";
import { API } from "aws-amplify";
import Select from "react-select"; // Import react-select

const apiName = "talentsapi";

const BatchFilter = ({ placeholder, onSelect, value }) => {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedOption, setSelectedOption] = useState(null); // State for selected option

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const path = "/batchFilterGet";
        const response = await API.get(apiName, path);

        if (Array.isArray(response)) {
          const optionValues = response
            .map((item) => ({
              label: item.batchName?.S || "Unknown", // For display
              value: item.serialID?.S || "Unknown", // Underlying value
              sort: parseInt(item.sort?.N) || 0,
            }))
            .sort((a, b) => a.sort - b.sort);

          // Remove the "All" option since you only want one selection
          setOptions(optionValues);
        } else {
          console.error("Unexpected response structure:", response);
        }
      } catch (error) {
        console.error("Error fetching dropdown options:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchOptions();
  }, []);

  // Handle selection change
  const handleChange = (selected) => {
    setSelectedOption(selected);
    onSelect(selected); // Pass the selected option to the parent component
  };

  return (
    <div>
      {loading ? (
        <p>Loading options...</p>
      ) : (
        <Select
          value={selectedOption}
          onChange={handleChange}
          options={options}
          placeholder={placeholder || "Select an option"}
          isSearchable
          isClearable
          // Ensures only one option can be selected
          isMulti={false}
          // Styles can be customized as needed
          styles={{
            menu: (provided) => ({ ...provided, zIndex: 9999 }),
          }}
        />
      )}
    </div>
  );
};

export default BatchFilter;
