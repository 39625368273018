import React, { useState, useEffect, useRef } from "react";
import { renderToString } from "react-dom/server";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./Email.css";

import CapCallTemplate from "./TemplatePreviews/CapCallTemplate";
import ReminderTemplate from "./TemplatePreviews/ReminderTemplate";
import CapDistTemplate from "./TemplatePreviews/CapDistTemplate";
import ReportingPackTemplate from "./TemplatePreviews/ReportingPackTemplate";
import CasFSTemplate from "./TemplatePreviews/CasFSTemplate";
import AYEFSTemplate from "./TemplatePreviews/AYEFSTemplate";
import K1Template from "./TemplatePreviews/K1Template";
import ProjFlowsTemplate from "./TemplatePreviews/ProjFlowsTemplate";

import { FaEdit, FaSave } from "react-icons/fa";

const EmailPreviewOG = ({
  setTemplateSubject, // ADD THIS PROP TO PASS THE SUBJECT BACK TO StepOne
  setTemplateContent, // ADD THIS PROP TO PASS THE EDITABLE TEXT BACK TO StepOne
  editableText,
  setEditableText, // Passed from the parent
  ...filters
}) => {
  // Access the nested filters object
  const { filters: innerFilters } = filters;
  // Now destructure the properties from innerFilters
  const { doc_type, fund, startDate, fundLongName, username } = innerFilters;

  // console.log("previeweditableText", editableText);

  // date helper function to pass to the preview contents
  const formatDate = () => {
    if (["K1Template", "Audited Year End FS"].includes(doc_type)) {
      return startDate.getFullYear().toString();
    } else if (
      ["Capital Call", "Call Reminder", "Capital Distribution"].includes(
        doc_type
      )
    ) {
      const month = startDate.getMonth() + 1; // months are zero-based
      const day = startDate.getDate();
      return `${month}/${day}`;
    } else if (
      ["Reporting Package", "CAS and FS", "Projected Flows"].includes(doc_type)
    ) {
      const quarter = Math.floor((startDate.getMonth() + 3) / 3);
      return `Q${quarter} ${startDate.getFullYear()}`;
    }
  };

  // helper function to calculate the email subject for the preview
  const getTemplateSubject = () => {
    switch (doc_type) {
      case "Capital Call":
        return <>{fund} - CAPITAL CALL Notice</>;
      case "Call Reminder":
        return (
          <>
            {fund} - Capital Call DUE TOMORROW {formatDate()} - Reminder
          </>
        );
      case "Capital Distribution":
        return <>{fund} - Distribution Notice - NO ACTION REQUIRED</>;
      case "Reporting Package":
        return <>{formatDate()} Reporting Package</>;
      case "CAS and FS":
        return <>{formatDate()} Capital Account Statements & Fund Financials</>;
      case "Audited Year End FS":
        return (
          <>{formatDate()} Audited Year End Financials are now available</>
        );
      case "K1Template":
        return (
          <>
            {fund} - {formatDate().toLocaleString("en-US", { year: "numeric" })}{" "}
            K-1 Available
          </>
        );
      case "Projected Flows":
        return (
          <>
            NO ACTION REQUIRED - 10Talents {formatDate()} Cash Flow Projection
          </>
        );
      default:
        return null;
    }
  };

  // const combineChildren = (children) => {
  //   let combinedString = "";
  //   React.Children.forEach(children, (child) => {
  //     if (typeof child === "string") {
  //       combinedString += child;
  //     }
  //   });
  //   return combinedString;
  // };

  // Custom hook to store previous values of a variable (for comparison)
  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    }, [value]);
    return ref.current;
  }

  // Store the previous `innerFilters` to compare with the current one
  const prevInnerFilters = usePrevious(innerFilters);

  // state for the preview subject
  const [subjectElement, setSubjectElement] = useState(getTemplateSubject());
  // setting the preview subject and template subject every time filters change
  useEffect(() => {
    // console.log("filters", filters);
    setSubjectElement(getTemplateSubject());
    // setTemplateSubject(subjectElement);
    // If editableText is empty, initialize it with the default template content
    // console.log("editableTexttt", editableText);
    // Compare current innerFilters with previous and check if editableText is empty
    // if (
    //   !editableText ||
    //   JSON.stringify(prevInnerFilters) !== JSON.stringify(innerFilters)
    // ) {
    setEditableText(getTemplateContentString());
    // setTemplateContent(editableText);
    // }
  }, [innerFilters]);

  // console.log("tempSub", subjectElement);

  useEffect(() => {
    setTemplateSubject(subjectElement);
    // If editableText is empty, initialize it with the default template content
    // console.log("editableTexttt", editableText);
  }, [subjectElement, setTemplateSubject]);

  // helper function calculates the template content
  const getTemplateContent = () => {
    switch (doc_type) {
      case "Capital Call":
        return <CapCallTemplate date={startDate} fundLongName={fundLongName} />;
      case "Call Reminder":
        return (
          <ReminderTemplate date={startDate} fundLongName={fundLongName} />
        );
      case "Capital Distribution":
        return <CapDistTemplate date={startDate} fundLongName={fundLongName} />;
      case "Reporting Package":
        return <ReportingPackTemplate date={formatDate()} />;
      case "CAS and FS":
        return <CasFSTemplate date={formatDate()} />;
      case "Audited Year End FS":
        return <AYEFSTemplate date={formatDate()} />;
      case "K1Template":
        return <K1Template year={formatDate()} fundLongName={fundLongName} />;
      case "Projected Flows":
        return <ProjFlowsTemplate date={formatDate()} />;
      default:
        return null;
    }
  };

  const getTemplateContentString = () => {
    const templateContent = getTemplateContent();
    return renderToString(templateContent);
  };

  // const [editableText, setEditableText] = useState(getTemplateContentString());
  const [isEditing, setIsEditing] = useState(false);

  const handleEditClick = () => {
    setIsEditing(true);
    // console.log("editableText", editableText);
  };

  const handleSaveClick = () => {
    setIsEditing(false);
    setTemplateContent(editableText); // Save the modified text to the parent
  };

  return (
    <div className="container">
      <div
        style={{
          fontWeight: "bold",
          border: "1px solid black",
          borderRadius: "5px",
          textAlign: "center",
          alignItems: "center",
          height: "auto",
          verticalAlign: "middle",
        }}
      >
        <p style={{ paddingTop: "20px" }}>{subjectElement}</p>
      </div>
      <div style={{ paddingTop: "20px", textAlign: "left" }}>
        <header>
          {!(
            doc_type === "Reporting Package" ||
            doc_type === "Audited Year End FS"
          ) && <p>(Familiar Name),</p>}
        </header>
        <main>
          <div>
            {isEditing ? (
              <>
                <button onClick={handleSaveClick}>
                  <FaSave /> Save
                </button>
                <ReactQuill
                  value={editableText}
                  onChange={(value) => setEditableText(value)}
                  style={{
                    background: "var(--primary-color)",
                  }}
                />
              </>
            ) : (
              <>
                <button onClick={handleEditClick}>
                  <FaEdit /> Edit
                </button>
                {editableText ? (
                  <div
                    style={{ paddingTop: "20px" }}
                    dangerouslySetInnerHTML={{ __html: editableText }}
                  />
                ) : (
                  getTemplateContent()
                )}
              </>
            )}
          </div>
          {doc_type !== "Call Reminder" && (
            <div>
              <p>You can access the document(s) directly here:</p>
              <div className="document-box">
                <a
                  href="https://10talentsportal.com"
                  style={{ color: "#000", textDecoration: "underline" }}
                >
                  This is the sample document
                </a>
              </div>
            </div>
          )}
          <div className="info-box">
            <p>Your username is as follows:</p>
            <p>Username: {username}</p>
            <p>
              Portal address:{" "}
              <a
                href="https://10talentsportal.com"
                style={{ color: "#000", textDecoration: "underline" }}
              >
                https://10talentsportal.com
              </a>
            </p>
          </div>
          <p>
            Please feel free to contact us at IR@10TalentsInvestors.com if you
            need assistance accessing the site.
          </p>
          <p>Regards,</p>
          <h4 style={{ marginTop: "0px", fontWeight: "bold" }}>
            10TALENTS INVESTORS
          </h4>
        </main>
        <footer>
          <div
            style={{
              background: "#231F20",
              color: "#fff",
              padding: "10px",
              textAlign: "center",
            }}
          >
            Confidential Information. Not intended for further distribution.
          </div>
        </footer>
      </div>
    </div>
  );
};

export default EmailPreviewOG;
