import React, { useState, useEffect } from "react";
import { SketchPicker } from "react-color";
// import "./Settings.css"; // Import your CSS file

const Settings = ({ isAuthenticated }) => {
  const [colors, setColors] = useState({
    primaryColor: "#e0e0e0",
    secondaryColor: "#cc5b2e",
    backgroundColor: "#cdcdcd",
    bodyBackgroundColor: "#cdcdcd",
    textColor: "#353535",
  });

  const [fontFamily, setFontFamily] = useState("Arial");

  const handleColorChange = (color, name) => {
    setColors({ ...colors, [name]: color.hex });
  };

  const handleFontChange = (event) => {
    setFontFamily(event.target.value);
  };

  useEffect(() => {
    const root = document.documentElement;
    root.style.setProperty("--primary-color", colors.primaryColor);
    root.style.setProperty("--secondary-color", colors.secondaryColor);
    root.style.setProperty("--background-color", colors.backgroundColor);
    root.style.setProperty(
      "--body-background-color",
      colors.bodyBackgroundColor
    );
    root.style.setProperty("--text-color", colors.textColor);
    root.style.setProperty("--font-family", fontFamily);
  }, [colors, fontFamily]);

  return (
    <div className="master-container">
      <h1>This is the settings page</h1>
      <div className="font-picker">
        <label>Font Family:</label>
        <select value={fontFamily} onChange={handleFontChange}>
          <option value="Arial">Arial</option>
          <option value="Verdana">Verdana</option>
          <option value="Helvetica">Helvetica</option>
          <option value="Times New Roman">Times New Roman</option>
          <option value="Courier New">Courier New</option>
          <option value="Georgia">Georgia</option>
          <option value="Tahoma">Tahoma</option>
          <option value="Segoe UI">Segoe UI</option>
          <option value="Consolas">Consolas</option>
          <option value="Impact">Impact</option>
          <option value="Courier New">Courier New</option>
        </select>
      </div>
      <div className="color-picker">
        <label>Primary Color</label>
        <SketchPicker
          color={colors.primaryColor}
          onChangeComplete={(color) => handleColorChange(color, "primaryColor")}
        />
      </div>
      <div className="color-picker">
        <label>Secondary Color</label>
        <SketchPicker
          color={colors.secondaryColor}
          onChangeComplete={(color) =>
            handleColorChange(color, "secondaryColor")
          }
        />
      </div>
      <div className="color-picker">
        <label>Background Color</label>
        <SketchPicker
          color={colors.backgroundColor}
          onChangeComplete={(color) =>
            handleColorChange(color, "backgroundColor")
          }
        />
      </div>
      <div className="color-picker">
        <label>Body Background Color</label>
        <SketchPicker
          color={colors.bodyBackgroundColor}
          onChangeComplete={(color) =>
            handleColorChange(color, "bodyBackgroundColor")
          }
        />
      </div>
      <div className="color-picker">
        <label>Text Color</label>
        <SketchPicker
          color={colors.textColor}
          onChangeComplete={(color) => handleColorChange(color, "textColor")}
        />
      </div>
    </div>
  );
};

export default Settings;
