import React, { useRef, useState, useEffect } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { FaInfoCircle } from "react-icons/fa"; // Import the info icon
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { API, Storage } from "aws-amplify";

const ActionDropdown = ({
  selectedList,
  headers,
  onActionSelect,
  actionStates,
  serialIDArray,
  dropdownContext, // New prop to identify context
}) => {
  const hasUrls = headers.includes("presignedUrl");
  const fileInputRef = useRef(null);
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [serialID, setSerialID] = useState(null); // Initialize state for serialID

  useEffect(() => {
    if (serialIDArray && serialIDArray.length > 0) {
      setSerialID(serialIDArray[0]?.serialID); // Set serialID from the array
      console.log("serialID", serialIDArray[0]?.serialID);
    } else {
      console.log("serialIDArray is empty or undefined");
    }
  }, [serialIDArray]);

  const handleAction = async (action) => {
    if (dropdownContext === "batch") {
      if (action === "delete") {
        await deleteBatch(serialID);
      } else if (action === "update") {
        await updateBatch(serialID);
      } else if (action === "create") {
        triggerFileInput();
        await uploadFiles(filesToUpload);
      }
    } else if (dropdownContext === "file") {
      if (action === "delete") {
        await softDeleteFile(selectedList);
      } else if (action === "update") {
        triggerFileInput();
        await updateFile(selectedList);
      } else if (action === "download" && hasUrls) {
        await downloadFilesAsZip(selectedList);
      }
    } else if (onActionSelect) {
      onActionSelect(action, selectedList);
    }
  };

  const deleteBatch = async (serialID) => {
    try {
      const apiName = "talentsapi";
      const path = "/deleteBatch";
      const init = {
        body: { serialID },
        headers: {
          "Content-Type": "application/json",
        },
      };

      const response = await API.post(apiName, path, init);
      console.log("Delete response:", response);
      alert("Batch deleted successfully.");
    } catch (error) {
      console.error("Error deleting batch:", error);
      alert("Error deleting batch. Please try again later.");
    }
  };

  const updateBatch = async (serialID) => {
    try {
      const apiName = "talentsapi";
      const path = "/updateBatch";

      const fund = localStorage.getItem("fund");
      const docType = localStorage.getItem("docType");

      const init = {
        body: { serialID, fund, docType },
        headers: {
          "Content-Type": "application/json",
        },
      };

      const response = await API.post(apiName, path, init);
      console.log("Update response:", response);
      alert("Batch updated successfully.");
    } catch (error) {
      console.error("Error updating batch:", error);
      alert("Error updating batch. Please try again later.");
    }
  };

  const updateFile = async (selectedList) => {
    try {
      const apiName = "talentsapi";
      const path = "/updateFile";

      const today = new Date().toISOString().split("T")[0]; // Get today's date in YYYY-MM-DD format

      // First, pass the selectedList to the API
      const init = {
        body: { selectedList },
        headers: {
          "Content-Type": "application/json",
        },
      };

      const response = await API.post(apiName, path, init);
      console.log("Update file API response:", response);

      // Then, for each item in the selectedList, perform the S3 upload
      for (const item of selectedList) {
        const transformedFileName = `${item.serialID}_${item.filepath}`;

        try {
          await Storage.put(transformedFileName, item.file, {
            contentType: item.file.type,
          });
          console.log(`File ${transformedFileName} uploaded successfully.`);
        } catch (error) {
          console.error(`Error uploading ${transformedFileName}:`, error);
          alert(
            `Error uploading ${transformedFileName}. Please try again later.`
          );
        }
      }

      alert("Files updated and uploaded successfully.");
    } catch (error) {
      console.error("Error updating files:", error);
      alert("Error updating files. Please try again later.");
    }
  };

  const softDeleteFile = async (selectedList) => {
    try {
      const transformedFilepaths = selectedList.map((item) => {
        return item.filepath.replace(/\//g, "_");
      });

      const apiName = "talentsapi";
      const path = "/softDeleteFile";
      const init = {
        body: { filepaths: transformedFilepaths },
        headers: {
          "Content-Type": "application/json",
        },
      };

      const response = await API.post(apiName, path, init);
      console.log("Soft delete response:", response);
      alert("File soft-deleted successfully.");
    } catch (error) {
      console.error("Error soft-deleting file:", error);
      alert("Error soft-deleting file. Please try again later.");
    }
  };

  const triggerFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = async (event) => {
    const files = Array.from(event.target.files);
    console.log("files", files);
    const invalidFiles = files.filter((file) => !validateFileName(file));

    if (invalidFiles.length > 0) {
      const invalidFileNames = invalidFiles
        .map((file) => `${file.name} should match the required format`)
        .join("\n");
      alert(
        `The following files do not follow the correct naming format:\n\n${invalidFileNames}`
      );
      return;
    }

    setFilesToUpload(files);
    if (files.length > 0) {
      const confirmation = window.confirm(
        `Are you sure you want to upload ${files.length} files?`
      );
      if (confirmation) {
        uploadFiles(files);
      }
    }
  };

  const uploadFiles = async (files) => {
    let successfullyUploadedCount = 0;

    for (const file of files) {
      const fileName = `${serialID}/${file.name.replace(/_/g, "/")}`;
      try {
        await Storage.put(fileName, file, {
          contentType: file.type,
        });
        successfullyUploadedCount++;
        console.log(`File ${fileName} uploaded successfully.`);
      } catch (error) {
        console.error(`Error uploading ${fileName}:`, error);
        alert(`Error uploading ${fileName}. Please try again later.`);
      }
    }

    if (successfullyUploadedCount > 0) {
      alert(`${successfullyUploadedCount} files uploaded successfully.`);
    }
  };

  const validateFileName = (file) => {
    const parts = file.name.split("_");
    return parts.length === 10 && file.name.endsWith(".pdf");
  };

  const downloadFilesAsZip = async (selectedItems) => {
    const zip = new JSZip();

    const downloadPromises = selectedItems.map(async (item) => {
      try {
        const response = await fetch(item.presignedUrl);
        const blob = await response.blob();
        const fileName = item.filepath.replace(/\//g, "_");
        zip.file(fileName, blob);
      } catch (error) {
        console.error(`Error downloading file ${item.filepath}:`, error);
      }
    });

    await Promise.all(downloadPromises);

    const zipBlob = await zip.generateAsync({ type: "blob" });
    saveAs(zipBlob, "documents.zip");
  };

  return (
    <div>
      <DropdownButton
        id="actions-dropdown"
        title="Actions"
        variant="secondary"
        className="mb-3"
      >
        {actionStates.download && (
          <Dropdown.Item
            onClick={() => handleAction("download")}
            disabled={selectedList.length === 0}
            className="custom-dropdown-item"
            title="Download the selected files as a ZIP"
          >
            Download <FaInfoCircle />
          </Dropdown.Item>
        )}
        {actionStates.create && (
          <Dropdown.Item
            onClick={() => handleAction("create")}
            disabled={selectedList.length === 0 || selectedList.length > 1}
            className="custom-dropdown-item"
            title="Add an item to the selected batch"
          >
            Add Item <FaInfoCircle />
          </Dropdown.Item>
        )}
        {actionStates.delete && (
          <Dropdown.Item
            onClick={() => handleAction("delete")}
            disabled={selectedList.length === 0}
            className="custom-dropdown-item"
          >
            Delete
          </Dropdown.Item>
        )}
        {actionStates.update && (
          <Dropdown.Item
            onClick={() => handleAction("update")}
            disabled={selectedList.length === 0}
            className="custom-dropdown-item"
          >
            Update
          </Dropdown.Item>
        )}
      </DropdownButton>

      {/* Hidden file input */}
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        multiple
        onChange={handleFileChange}
      />
    </div>
  );
};

export default ActionDropdown;
