// CreateModal.jsx

import React, { useState, useEffect } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { Modal, Button } from "react-bootstrap";
import {
  // Import your mutations
  createFeederFund,
  createContact,
  createAccount,
  createInvestor,
  createOpportunity,
  createDocPackageName,
  createDocPackagePermission,
  updateInvestor,
  updateAccount,
  updateContact,
  updateDocPackageName,
  updateDocPackagePermission,
  updateOpportunity,
  updateFeederFund,
} from "../../graphql/mutations";
import {
  // Import your queries
  listAllAccounts,
  listAllInvestors,
  listAllFeederFunds,
  listAllContacts,
  listAllDocPackageNames,
  listAllOpportunities,
} from "../../graphql/queries";

const CreateModal = ({
  isOpen,
  onClose,
  type,
  editRecord,
  keyField,
  visibleColumns,
  columnsMap,
  selectedList,
}) => {
  const [formData, setFormData] = useState({});
  const [relatedOptions, setRelatedOptions] = useState({});
  const [loading, setLoading] = useState(false);
  const isEdit = Boolean(editRecord);

  // const fields = columnsMap[type]
  //   ? isEdit
  //     ? columnsMap[type].updateFields
  //     : columnsMap[type].createFields
  //   : [];
  // console.log("fields", fields);

  // console.log("CreateModal type:", type);
  // console.log("CreateModal editRecord:", editRecord);

  useEffect(() => {
    if (isEdit) {
      setFormData(editRecord);
    } else {
      setFormData({});
    }
  }, [isEdit, editRecord]);

  useEffect(() => {
    // Fetch related data for dropdowns based on the type
    const fetchRelatedData = async () => {
      setLoading(true);
      try {
        if (type === "Contacts") {
          // Fetch accounts for account selection
          const accountsResponse = await API.graphql({
            query: listAllAccounts,
          });
          const accounts = accountsResponse.data.listAllAccounts || [];
          setRelatedOptions({ accounts });
        } else if (type === "Opportunities") {
          // Fetch investors and feeder funds
          const investorsResponse = await API.graphql({
            query: listAllInvestors,
          });
          const feederFundsResponse = await API.graphql({
            query: listAllFeederFunds,
          });
          const investors = investorsResponse.data.listAllInvestors || [];
          const feederFunds = feederFundsResponse.data.listAllFeederFunds || [];
          setRelatedOptions({ investors, feederFunds });
        } else if (type === "Investors") {
          // Fetch accounts for account selection
          const accountsResponse = await API.graphql({
            query: listAllAccounts,
          });
          const accounts = accountsResponse.data.listAllAccounts || [];
          setRelatedOptions({ accounts });
        } else if (type === "Doc. Package Permissions") {
          // Fetch doc package names and contacts
          const docPackagesResponse = await API.graphql({
            query: listAllDocPackageNames,
          });
          const contactsResponse = await API.graphql({
            query: listAllContacts,
          });
          const opportunityResponse = await API.graphql({
            query: listAllOpportunities,
          });
          const docPackages =
            docPackagesResponse.data.listAllDocPackageNames || [];
          const contacts = contactsResponse.data.listAllContacts || [];
          const opportunity =
            opportunityResponse.data.listAllOpportunities || [];
          setRelatedOptions({ docPackages, contacts, opportunity });
        }
        // Add other types as needed
      } catch (error) {
        console.error("Error fetching related data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchRelatedData();
    // console.log("relatedOptions", relatedOptions);
  }, [type]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    console.log("formData", formData);
  };

  const handleSubmit = async () => {
    // Determine the mutation based on type and whether it's an update or create
    let mutation;
    if (editRecord) {
      switch (type) {
        case "Feeder Funds":
          mutation = updateFeederFund;
          break;
        case "Contacts":
          mutation = updateContact;
          break;
        case "Accounts":
          mutation = updateAccount;
          break;
        case "Investors":
          mutation = updateInvestor;
          break;
        case "Opportunities":
          mutation = updateOpportunity;
          break;
        case "Doc. Packages":
          mutation = updateDocPackageName;
          break;
        case "Doc. Package Permissions":
          mutation = updateDocPackagePermission;
          break;
        default:
          console.error("Unknown type for update:", type);
          return;
      }
    } else {
      switch (type) {
        case "Feeder Funds":
          mutation = createFeederFund;
          break;
        case "Contacts":
          mutation = createContact;
          break;
        case "Accounts":
          mutation = createAccount;
          break;
        case "Investors":
          mutation = createInvestor;
          break;
        case "Opportunities":
          mutation = createOpportunity;
          break;
        case "Doc. Packages":
          mutation = createDocPackageName;
          break;
        case "Doc. Package Permissions":
          mutation = createDocPackagePermission;
          break;
        default:
          console.error("Unknown type for create:", type);
          return;
      }
    }
    console.log("columnsMap", columnsMap);

    // Determine the fields to use based on whether there is an editRecord
    const inputFields = editRecord
      ? columnsMap.updateFields
      : columnsMap.createFields;

    // Filter formData to include only the fields specified in inputFields
    const input = inputFields.reduce((obj, key) => {
      if (formData[key] !== undefined) obj[key] = formData[key];
      return obj;
    }, {});

    if (editRecord) {
      // Add the ID field if updating
      input.id = formData.id;
    }

    console.log("Submitting input:", input);

    try {
      await API.graphql({
        query: mutation,
        variables: { input },
      });
      onClose(); // Close modal on successful submission
    } catch (error) {
      console.error("Error creating or updating record:", error);
    }
  };

  useEffect(() => {
    if (type === "Opportunities") {
      // Find the selected investor name
      const investor = relatedOptions.investors?.find(
        (inv) => inv.investor_id === formData.investor_id
      );
      const investorName = investor ? investor.investor_name : "";

      // Find the selected feeder fund name
      const feederFund = relatedOptions.feederFunds?.find(
        (fund) => fund.feeder_fund_id === formData.feeder_fund_id
      );
      const feederFundName = feederFund ? feederFund.feeder_fund_name : "";

      // Combine names if both are selected
      const opportunityName =
        investorName && feederFundName
          ? `${investorName} - ${feederFundName}`
          : "";

      // Update formData if the opportunity name has changed
      if (formData.opportunity_name !== opportunityName) {
        setFormData((prevData) => ({
          ...prevData,
          opportunity_name: opportunityName,
        }));
      }
    }
  }, [
    type,
    formData.investor_id,
    formData.feeder_fund_id,
    formData.opportunity_name,
    relatedOptions,
  ]);

  const renderFormFields = () => {
    if (loading) {
      return <p>Loading...</p>;
    }

    if (type === "Feeder Funds") {
      return (
        <>
          <input
            name="feeder_fund_name"
            placeholder="Feeder Fund Name"
            value={formData.feeder_fund_name || ""}
            onChange={handleInputChange}
          />
          <input
            name="fund_number"
            placeholder="Fund Number"
            value={formData.fund_number || ""}
            onChange={handleInputChange}
          />
          <input
            name="feeder_fund_long_name"
            placeholder="Feeder Fund Long Name"
            value={formData.feeder_fund_long_name || ""}
            onChange={handleInputChange}
          />
          <input
            name="feeder_strategy"
            placeholder="Feeder Strategy"
            value={formData.feeder_strategy || ""}
            onChange={handleInputChange}
          />
          <input
            name="feeder_industry"
            placeholder="Feeder Industry"
            value={formData.feeder_industry || ""}
            onChange={handleInputChange}
          />
        </>
      );
    } else if (type === "Contacts") {
      return (
        <>
          <input
            name="familiar_name"
            placeholder="Familiar Name"
            value={formData.familiar_name || ""}
            onChange={handleInputChange}
          />
          <input
            name="contact_name"
            placeholder="Contact Name"
            value={formData.contact_name || ""}
            onChange={handleInputChange}
          />
          <input
            name="email"
            placeholder="Email"
            value={formData.email || ""}
            onChange={handleInputChange}
          />
          <input
            name="phone"
            placeholder="Phone"
            value={formData.phone || ""}
            onChange={handleInputChange}
          />
          {/* Account Selection */}
          <label>Account:</label>
          <select
            name="account_id"
            value={formData.account_id || ""}
            onChange={handleInputChange}
          >
            <option value="">Select Account</option>
            {relatedOptions.accounts &&
              relatedOptions.accounts.map((account) => (
                <option key={account.id} value={account.account_id}>
                  {account.account_name}
                </option>
              ))}
          </select>
        </>
      );
    } else if (type === "Accounts") {
      return (
        <>
          <input
            name="account_name"
            placeholder="Account Name"
            value={formData.account_name || ""}
            onChange={handleInputChange}
          />
        </>
      );
    } else if (type === "Investors") {
      return (
        <>
          <input
            name="investor_name"
            placeholder="Investor Name"
            value={formData.investor_name || ""}
            onChange={handleInputChange}
          />
          {/* Account Selection */}
          <label>Account:</label>
          <select
            name="account_id"
            value={formData.account_id || ""}
            onChange={handleInputChange}
          >
            <option value="">Select Account</option>
            {relatedOptions.accounts &&
              relatedOptions.accounts.map((account) => (
                <option key={account.id} value={account.account_id}>
                  {account.account_name}
                </option>
              ))}
          </select>
        </>
      );
    } else if (type === "Opportunities") {
      return (
        <>
          {/* Investor Selection */}
          <label>Investor:</label>
          <select
            name="investor_id"
            value={formData.investor_id || ""}
            onChange={handleInputChange}
          >
            <option value="">Select Investor</option>
            {relatedOptions.investors &&
              relatedOptions.investors.map((investor) => (
                <option key={investor.id} value={investor.investor_id}>
                  {investor.investor_name}
                </option>
              ))}
          </select>
          {/* Feeder Fund Selection */}
          <label>Feeder Fund:</label>
          <select
            name="feeder_fund_id"
            value={formData.feeder_fund_id || ""}
            onChange={handleInputChange}
          >
            <option value="">Select Feeder Fund</option>
            {relatedOptions.feederFunds &&
              relatedOptions.feederFunds.map((fund) => (
                <option key={fund.id} value={fund.feeder_fund_id}>
                  {fund.feeder_fund_name}
                </option>
              ))}
          </select>
          <label>Opportunity Name (autogenerated):</label>
          <input
            name="opportunity_name"
            placeholder="Opportunity Name"
            value={formData.opportunity_name || ""}
            readOnly
          />
        </>
      );
    } else if (type === "Doc. Packages") {
      return (
        <>
          <input
            name="doc_package_name"
            placeholder="Doc Package Name"
            value={formData.doc_package_name || ""}
            onChange={handleInputChange}
          />
        </>
      );
    } else if (type === "Doc. Package Permissions") {
      return (
        <>
          {/* Doc Package Selection */}
          <label>Doc Package:</label>
          <select
            name="doc_package_name_id"
            value={formData.doc_package_name_id || ""}
            onChange={handleInputChange}
          >
            <option value="">Select Doc Package</option>
            {relatedOptions.docPackages &&
              relatedOptions.docPackages.map((pkg) => (
                <option key={pkg.id} value={pkg.doc_package_name_id}>
                  {pkg.doc_package_name}
                </option>
              ))}
          </select>
          {/* Contact Selection */}
          <label>Contact:</label>
          <select
            name="contact_id"
            value={formData.contact_id || ""}
            onChange={handleInputChange}
          >
            <option value="">Select Contact</option>
            {relatedOptions.contacts &&
              relatedOptions.contacts.map((contact) => (
                <option key={contact.id} value={contact.contact_id}>
                  {contact.familiar_name}
                </option>
              ))}
          </select>
          {/* Opportunity Selection */}
          <label>Opportunity:</label>
          <select
            name="opportunity_id"
            value={formData.opportunity_id || ""}
            onChange={handleInputChange}
          >
            <option value="">Select Opportunity</option>
            {relatedOptions.opportunity &&
              relatedOptions.opportunity.map((opportunity) => (
                <option key={opportunity.id} value={opportunity.opportunity_id}>
                  {opportunity.opportunity_name}
                </option>
              ))}
          </select>
        </>
      );
    } else {
      return <p>Form for {type} not defined.</p>;
    }
  };

  return (
    <Modal show={isOpen} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Create {type}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{renderFormFields()}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleSubmit} disabled={loading}>
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CreateModal;
