import React, { useEffect, useState } from "react";
import "./Components/Dashboard.css"; // Import the CSS file
import useSessionChecker from "./useSessionChecker";

const HomePage = () => {
  const { cognitoPayload, userInfo, investorsInfo } = useSessionChecker();
  const [timeRemaining, setTimeRemaining] = useState(null);

  // console.log("cognitoPayload", cognitoPayload);
  // console.log("userInfo", userInfo);
  // console.log("investorsInfo", investorsInfo);

  useEffect(() => {
    if (cognitoPayload?.exp) {
      const calculateTimeLeft = () => {
        const now = Math.floor(Date.now() / 1000); // Current time in seconds
        const timeLeft = cognitoPayload.exp - now; // Time left in seconds

        setTimeRemaining(timeLeft > 0 ? timeLeft : 0);
      };

      // Calculate the initial time remaining
      calculateTimeLeft();

      // Update every second
      const intervalId = setInterval(calculateTimeLeft, 1000);

      // Cleanup on unmount
      return () => clearInterval(intervalId);
    }
  }, [cognitoPayload]);

  // Convert timeRemaining to a more readable format (minutes:seconds)
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };

  // Check if data is loaded before rendering
  if (!cognitoPayload || !userInfo || !investorsInfo) {
    return <div>Loading...</div>;
  }

  // Access the user's familiar name
  const familiarName = userInfo?.[0]?.familiar_name || "User";

  return (
    <div className="master-container">
      <div className="portfolio">
        <div className="welcome-box">
          <h3>Welcome, {familiarName}</h3>
          <p>({cognitoPayload.email})</p>

          <p style={{ textDecoration: "underline" }}>Investors:</p>
          {/* Map over investorsInfo to display investor names */}
          {investorsInfo && investorsInfo.length > 0 ? (
            <ul>
              {investorsInfo.map((investor) => (
                <li key={investor.id}>{investor.investor_name}</li>
              ))}
            </ul>
          ) : (
            <p>No investors found.</p>
          )}

          <p style={{ fontWeight: "bold", color: "white", textAlign: "right" }}>
            User Group: {cognitoPayload["cognito:groups"]}
          </p>
          {timeRemaining !== null && (
            <p style={{ color: timeRemaining < 300 ? "red" : "black" }}>
              Session expires in: {formatTime(timeRemaining)}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default HomePage;
