import React, { useState, useEffect } from "react";
import { API, Auth, Storage } from "aws-amplify";
import FormWizard from "react-form-wizard-component";
import "react-form-wizard-component/dist/style.css";
import EmailFilters from "./EmailFilters";
import EmailPreviewOG from "./EmailPreviewOG";
import EmailList from "./EmailList";
import { Row, Col, Modal, Button } from "react-bootstrap";
import { FaEye, FaEnvelope, FaList, FaUpload, FaCheck } from "react-icons/fa";
import Select from "react-select";

const apiName = "talentsapi";

export default function FormWizardSample() {
  // Auth comes first - state for token and useEffect to grab it
  const [token, setToken] = useState("");

  useEffect(() => {
    const fetchToken = async () => {
      const session = await Auth.currentSession();
      const jwtToken = session.getIdToken().getJwtToken();
      setToken(jwtToken);
    };

    fetchToken();
  }, []);

  // State for the filters
  const [filters, setFilters] = useState({
    doc_type: "",
    fund: "",
    startDate: new Date(),
    fundLongName: "",
    formattedDate: "",
    showPreview: true,
    response5: null,
    batchName: "",
    batchID: "",
  });

  const [templateSubject, setTemplateSubject] = useState(""); // State for template subject
  const [templateContent, setTemplateContent] = useState(""); // State for template content
  const [editableText, setEditableText] = useState(""); // Editable text passed to `EmailPreviewOG`

  // STATE TO MANAGE VERIFIED IDENTITIES AND SELECTED EMAILS
  const [verifiedIdentities, setVerifiedIdentities] = useState([]);
  const [toEmails, setToEmails] = useState([]); // Changed to array
  const [ccEmails, setCcEmails] = useState([]); // Changed to array
  const [showSendPreviewModal, setShowSendPreviewModal] = useState(false);

  // STATE TO TRACK SUCCESS
  const [isPreviewSent, setIsPreviewSent] = useState(false);
  const [isApprovalUploaded, setIsApprovalUploaded] = useState(false);
  const [selectedFinalEmails, setSelectedFinalEmails] = useState([]);

  // FUNCTION TO FETCH VERIFIED IDENTITIES FROM AWS SES
  const fetchVerifiedEmails = async () => {
    try {
      const currentSession = await Auth.currentSession();
      const token = currentSession.getIdToken().getJwtToken();

      const path = "/listVerifiedEmails"; // MODIFY THIS PATH TO MATCH YOUR API
      const options = {
        headers: {
          Authorization: token,
        },
      };

      const response = await API.get(apiName, path, options);
      setVerifiedIdentities(response.verifiedEmails); // ASSUMING THE RESPONSE HAS 'verifiedEmails'
    } catch (error) {
      console.error("Error fetching verified emails:", error);
    }
  };

  // Handle the submission of filters from EmailFilters
  const handleFilterSubmit = (newFilters) => {
    setFilters(newFilters); // Update the filters
  };

  console.log("batchID", filters.batchID);

  // FUNCTION TO SEND THE PREVIEW EMAIL
  const sendPreviewEmail = async (isFinalEmail) => {
    try {
      const path = "/sendpreview";

      const requestInfo = {
        headers: {
          Authorization: token,
        },
        body: {
          toAddresses: toEmails.map((emailObj) => emailObj.value),
          ccAddresses: ccEmails.map((emailObj) => emailObj.value),
          fund: filters.fund,
          doc_type: filters.doc_type,
          startDate: filters.formattedDate,
          emailPreviewContent: editableText,
          emailSubject: templateSubject.props
            ? templateSubject.props.children.join("")
            : templateSubject,
          batchID: filters.batchID,
          isFinalEmail: isFinalEmail, // Pass the parameter to the backend
        },
      };

      const response = await API.put(apiName, path, requestInfo);
      console.log("Preview sent:", response);

      // If the response is positive, mark the preview as sent
      setIsPreviewSent(true);
      setShowSendPreviewModal(false);
    } catch (error) {
      console.error("Error sending preview:", error);
      setIsPreviewSent(false); // Reset in case of error
    }
  };

  // FUNCTION TO HANDLE APPROVAL UPLOAD
  const uploadApproval = async (file) => {
    const fileName = `email_approvals/${filters.batchName}`;
    try {
      const result = await Storage.put(fileName, file, {
        level: "public",
        contentType: file.type,
      });
      console.log("Approval uploaded:", result);

      // If upload succeeds, mark approval as uploaded
      setIsApprovalUploaded(true);
    } catch (error) {
      console.error("Error uploading approval:", error);
      setIsApprovalUploaded(false); // Reset in case of error
    }
  };

  const handleComplete = () => {
    console.log("Email completed!");
    sendPreviewEmail(true); // Pass true to indicate final email
    alert("The form has been completed successfully!");
  };

  const tabChanged = ({ prevIndex, nextIndex }) => {
    // console.log("Tab changed from:", prevIndex, "to:", nextIndex);
  };

  const checkValidateTab = () => {
    if (isPreviewSent && isApprovalUploaded) {
      return true;
    }
    return false;
  };

  const errorMessages = () => {
    alert("You must send a preview email and upload approval to continue.");
  };

  const handleUploadApprovalClick = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.onchange = (event) => {
      const file = event.target.files[0];
      if (file) {
        uploadApproval(file);
      }
    };
    input.click();
  };

  // HANDLE SEND PREVIEW BUTTON CLICK
  const handleSendPreview = async (event) => {
    event.preventDefault();
    await fetchVerifiedEmails();
    setShowSendPreviewModal(true);
  };

  // Prepare options for react-select
  const emailOptions = verifiedIdentities.map((email) => ({
    value: email,
    label: email,
  }));

  return (
    <div className="master-container" style={{ maxWidth: "60%" }}>
      <FormWizard
        onComplete={handleComplete}
        onTabChange={tabChanged}
        color="var(--secondary-color)"
      >
        <FormWizard.TabContent title="Preview Email" icon={<FaEye />}>
          {/* Log the filters being passed into EmailFilters */}
          <form>
            <Row style={{ marginBottom: "10px" }}>
              <Col style={{ textAlign: "center" }}>
                <button type="button" onClick={handleSendPreview}>
                  <FaEnvelope /> Send Preview{" "}
                  {isPreviewSent && <FaCheck style={{ color: "green" }} />}
                </button>
              </Col>
              <Col>
                <button type="button" onClick={handleUploadApprovalClick}>
                  <FaUpload /> Upload Approval{" "}
                  {isApprovalUploaded && <FaCheck style={{ color: "green" }} />}
                </button>
              </Col>
            </Row>
          </form>
          <EmailFilters
            initialFilters={filters}
            onFilterSubmitted={handleFilterSubmit}
          />
          <EmailPreviewOG
            filters={filters}
            setTemplateSubject={setTemplateSubject}
            setTemplateContent={setTemplateContent}
            editableText={editableText}
            setEditableText={setEditableText}
          />
        </FormWizard.TabContent>
        <FormWizard.TabContent
          title="Review Email List"
          icon={<FaList />}
          isValid={checkValidateTab()}
          validationError={errorMessages}
        >
          <EmailList
            filters={filters}
            setSelectedFinalEmails={setSelectedFinalEmails}
          />
        </FormWizard.TabContent>
        <FormWizard.TabContent title="Send Final Email" icon={<FaEnvelope />}>
          <EmailPreviewOG
            filters={filters}
            setTemplateSubject={setTemplateSubject}
            setTemplateContent={setTemplateContent}
            editableText={editableText}
            setEditableText={setEditableText}
          />
        </FormWizard.TabContent>
      </FormWizard>

      {/* SEND PREVIEW MODAL */}
      <Modal
        show={showSendPreviewModal}
        onHide={() => setShowSendPreviewModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Send Email Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <label>To:</label>
            <Select
              isMulti
              options={emailOptions}
              value={toEmails}
              onChange={(selectedOptions) => setToEmails(selectedOptions)}
              placeholder="Select or type emails..."
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </div>
          <div style={{ marginTop: "10px" }}>
            <label>CC:</label>
            <Select
              isMulti
              options={emailOptions}
              value={ccEmails}
              onChange={(selectedOptions) => setCcEmails(selectedOptions)}
              placeholder="Select or type emails..."
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowSendPreviewModal(false)}
          >
            Cancel
          </Button>
          <Button variant="primary" onClick={() => sendPreviewEmail(false)}>
            {" "}
            {/* this is false because it is not the final email so it won't get an audit record */}
            Send
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
