// CRM.jsx
import React, { useState } from "react";
import RecordManager from "./RecordManager";
import RibbonBar from "./RibbonBar";
import "./RibbonBar.css"; // Import the CSS

const CRM = ({ isAuthenticated }) => {
  const options = [
    "Feeder Funds",
    "Contacts",
    "Accounts",
    "Investors",
    "Opportunities",
    "Doc. Packages",
    "Doc. Package Permissions",
  ];

  const [selectedOption, setSelectedOption] = useState(options[0]);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };
  // console.log("selected option:", selectedOption);
  // Map selectedType to visibleColumns, filterableColumns, createFields, updateFields, and deleteFields
  const columnsMap = {
    "Feeder Funds": {
      visibleColumns: [
        "fund_number",
        "feeder_fund_name",
        "feeder_fund_long_name",
        "feeder_strategy",
        "feeder_industry",
      ],
      filterableColumns: [
        "feeder_fund_name",
        "feeder_strategy",
        "feeder_industry",
      ],
      createFields: [
        "fund_number",
        "feeder_fund_name",
        "feeder_fund_long_name",
        "feeder_strategy",
        "feeder_industry",
      ],
      updateFields: [
        "id", // Include ID for updates
        "fund_number",
        "feeder_fund_name",
        "feeder_fund_long_name",
        "feeder_strategy",
        "feeder_industry",
      ],
      deleteFields: ["id"], // Only ID is needed for deletions
    },
    Contacts: {
      visibleColumns: [
        "familiar_name",
        "contact_name",
        "email",
        "phone",
        "account_name",
      ],
      filterableColumns: [
        "familiar_name",
        "contact_name",
        "email",
        "account_name",
      ],
      createFields: [
        "familiar_name",
        "contact_name",
        "email",
        "phone",
        "account_id", // Use account_id for relations
      ],
      updateFields: [
        "id",
        "familiar_name",
        "contact_name",
        "email",
        "phone",
        "account_id",
        "contact_id",
      ],
      deleteFields: ["id"],
    },
    Accounts: {
      visibleColumns: ["account_name"],
      filterableColumns: ["account_name"],
      createFields: ["account_name"],
      updateFields: ["id", "account_name", "account_id"],
      deleteFields: ["id"],
    },
    Investors: {
      visibleColumns: ["investor_name", "account_name"],
      filterableColumns: ["investor_name"],
      createFields: ["investor_name", "account_id"],
      updateFields: ["id", "investor_name", "account_id", "investor_id"],
      deleteFields: ["id"],
    },
    Opportunities: {
      visibleColumns: ["opportunity_name", "investor_name", "feeder_fund_name"],
      filterableColumns: [
        "opportunity_name",
        "investor_name",
        "feeder_fund_name",
      ],
      createFields: ["opportunity_name", "investor_id", "feeder_fund_id"],
      updateFields: [
        "id",
        "opportunity_name",
        "investor_id",
        "feeder_fund_id",
        "opportunity_id",
      ],
      deleteFields: ["id"],
    },
    "Doc. Packages": {
      visibleColumns: ["doc_package_name"],
      filterableColumns: ["doc_package_name"],
      createFields: ["doc_package_name"],
      updateFields: ["id", "doc_package_name"],
      deleteFields: ["id"],
    },
    "Doc. Package Permissions": {
      visibleColumns: ["doc_package_name", "contact_name", "opportunity_name"],
      filterableColumns: [
        "doc_package_name",
        "contact_name",
        "opportunity_name",
      ],
      createFields: ["doc_package_name_id", "contact_id", "opportunity_id"],
      updateFields: [
        "id",
        "doc_package_name_id",
        "contact_id",
        "opportunity_id",
        "doc_package_permission_id",
      ],
      deleteFields: ["id"],
    },
  };

  return (
    <div className="master-container">
      <RibbonBar
        options={options}
        selectedOption={selectedOption}
        onOptionClick={handleOptionClick}
      />
      <RecordManager
        selectedType={selectedOption}
        columnsMap={columnsMap[selectedOption]}
      />
    </div>
  );
};

export default CRM;
