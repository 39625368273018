// theme.js
import { defaultTheme } from "@aws-amplify/ui-react";

const customTheme = {
  name: "custom-theme",
  overrides: [
    {
      colorMode: "dark",
      tokens: {
        colors: {
          background: {
            primary: { value: "#f0f4f8" },
          },
          font: {
            primary: { value: "#1a202c" },
          },
          brand: {
            primary: {
              10: { value: "#e6f7ff" },
              80: { value: "#1890ff" },
              100: { value: "#096dd9" },
            },
          },
        },
        fonts: {
          default: {
            variable: { value: "'InterVariable', sans-serif" },
            static: { value: "'Inter', sans-serif" },
          },
        },
        space: {
          small: { value: "8px" },
          medium: { value: "16px" },
          large: { value: "24px" },
        },
        components: {
          button: {
            primary: {
              backgroundColor: { value: "{colors.brand.primary.80}" },
              color: { value: "{colors.white}" },
              borderRadius: { value: "8px" },
              padding: { value: "{space.medium}" },
              _hover: {
                backgroundColor: { value: "{colors.brand.primary.100}" },
              },
            },
          },
          heading: {
            1: {
              fontSize: { value: "2.5rem" },
              fontWeight: { value: "600" },
            },
          },
        },
      },
    },
  ],
};

export default customTheme;
