import React, { useEffect, useState } from "react";
import { API } from "aws-amplify";
import DropdownFilter from "./DropdownFilter";

const apiName = "talentsapi";

const FundFilter = ({ placeholder, onSelect, value }) => {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const path = "/fundFilterGet";
        const response = await API.get(apiName, path);

        // console.log("API Response:", response);

        // Assuming the response itself is an array of objects
        if (Array.isArray(response)) {
          // Extract and sort options based on the sort field
          const optionValues = response
            .map((item) => ({
              value: item.fund_manager_concat?.S || "Unknown",
              sort: parseInt(item.sort?.N) || 0,
            }))
            .sort((a, b) => a.sort - b.sort)
            .map((item) => item.value);

          // Add "All" as the first option
          setOptions([...optionValues]);
          //   console.log("Sorted Options:", optionValues);
        } else {
          console.error("Unexpected response structure:", response);
        }
      } catch (error) {
        console.error("Error fetching dropdown options:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchOptions();
  }, []);

  return (
    <div>
      {loading ? (
        <p>Loading options...</p>
      ) : (
        <DropdownFilter
          options={options}
          onSelect={onSelect}
          placeholder={placeholder}
          value={value}
        />
      )}
    </div>
  );
};

export default FundFilter;
