import React, { useState, useEffect } from "react";
import { API, Storage } from "aws-amplify";
import { Table, Spinner, Row, Col, Form } from "react-bootstrap";
import { FaTrash, FaCheck, FaTimes } from "react-icons/fa";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "bootstrap/dist/css/bootstrap.min.css";
import DropdownFilter from "../DropdownFilter";
import FundFilter from "../FundFilter";
import RibbonBar from "../CRM system/RibbonBar";

const apiName = "talentsapi";

const getNearestQuarterEnd = () => {
  const now = new Date();
  const quarterEndMonths = [2, 5, 8, 11]; // March, June, September, December
  let quarterMonth =
    quarterEndMonths.find((month) => month >= now.getMonth()) || 2; // Default to March if all are in the past
  const quarterEndDate = new Date(now.getFullYear(), quarterMonth + 1, 0); // Last day of the quarter month
  return quarterEndDate;
};

const formatDate = (docType, startDate) => {
  if (["Tax", "Audited Year End FS"].includes(docType)) {
    return startDate.getFullYear().toString();
  } else if (["Capital Call", "Capital Distribution"].includes(docType)) {
    const month = startDate.getMonth() + 1; // months are zero-based
    const day = startDate.getDate();
    const Year = startDate.getFullYear();
    return `${month}.${day}.${Year}`;
  } else if (
    ["Reporting Package", "CAS and FS", "Projected Flows"].includes(docType)
  ) {
    const quarter = Math.floor((startDate.getMonth() + 3) / 3);
    return `Q${quarter} ${startDate.getFullYear()}`;
  }
  return "";
};

const FileUpload = () => {
  const options = ["File Name Method", "Manual Filters"];
  const docTypeOptions = [
    "Tax",
    "Audited Year End FS",
    "Capital Call",
    "Capital Distribution",
    "Reporting Package",
    "CAS and FS",
    "Projected Flows",
  ];

  const [selectedOption, setSelectedOption] = useState(options[0]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedFileIndices, setSelectedFileIndices] = useState([]);
  const [uploadedFileCount, setUploadedFileCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [fund, setFund] = useState("");
  const [docType, setDocType] = useState("");
  const [uploadDate, setUploadDate] = useState(getNearestQuarterEnd());
  const [now, setNow] = useState(new Date());

  const hasMergefilesPrefix = selectedFiles.some((file) => {
    const hasPageNumber = /_page\d+\.(doc|pdf|xlsx|xls|csv|txt|ppt|pptx)$/.test(
      file.name
    );
    return hasPageNumber; // If any file matches the page number rule, "mergefiles/" will be added.
  });

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setFund("");
    setDocType("");
    setSelectedFiles([]);
    setSelectedFileIndices([]);
  };

  // Validate file name for the "File Name Method"
  const validateFileName = (fileName) => {
    const regex =
      /^([a-zA-Z0-9\s]+)_([a-zA-Z\s]+)_([a-zA-Z0-9\s]+)_([a-zA-Z0-9]+)(?:_page(\d+))?\.(doc|pdf|xlsx|xls|csv|txt|ppt|pptx)$/;
    return regex.test(fileName);
  };

  const extractFundAndDocType = (files) => {
    const extractedFunds = new Set();
    const extractedDocTypes = new Set();

    files.forEach((file) => {
      const fileNameParts = file.name.split("_");
      if (fileNameParts.length >= 2) {
        extractedFunds.add(fileNameParts[0].trim());
        extractedDocTypes.add(fileNameParts[1].trim());
      }
    });

    const fundsArray = Array.from(extractedFunds);
    const docTypesArray = Array.from(extractedDocTypes);

    return { fundsArray, docTypesArray };
  };

  const onFileChange = (event) => {
    const files = Array.from(event.target.files || []);
    setSelectedFiles(files);

    if (selectedOption === "File Name Method") {
      const { fundsArray, docTypesArray } = extractFundAndDocType(files);

      if (fundsArray.length === 1 && docTypesArray.length === 1) {
        setFund(fundsArray[0]);
        setDocType(docTypesArray[0]);
      } else {
        const proceed = window.confirm(
          "These documents may not be from the same fund or of the same document type, is it okay to proceed?"
        );

        if (!proceed) {
          setSelectedFiles([]); // Clear files if user cancels
          return;
        }

        setFund(fundsArray[0] || "Unknown Fund");
        setDocType(docTypesArray[0] || "Unknown DocType");
      }
    }
  };

  const removeFile = (index) => {
    const newSelectedFiles = [...selectedFiles];
    newSelectedFiles.splice(index, 1);
    setSelectedFiles(newSelectedFiles);
    setSelectedFileIndices([]);
  };

  const removeSelectedFiles = () => {
    const remainingFiles = selectedFiles.filter(
      (_, index) => !selectedFileIndices.includes(index)
    );
    setSelectedFiles(remainingFiles);
    setSelectedFileIndices([]);
  };

  const toggleSelectAll = () => {
    if (selectedFileIndices.length === selectedFiles.length) {
      setSelectedFileIndices([]); // Unselect all
    } else {
      setSelectedFileIndices(selectedFiles.map((_, index) => index)); // Select all
    }
  };

  const toggleFileSelection = (index) => {
    if (selectedFileIndices.includes(index)) {
      setSelectedFileIndices(
        selectedFileIndices.filter((selectedIndex) => selectedIndex !== index)
      );
    } else {
      setSelectedFileIndices([...selectedFileIndices, index]);
    }
  };

  const logBatch = async (serialID, readableTimestamp, fund, docType) => {
    const path = "/logBatch"; // Update this path if necessary for your API
    const requestInfo = {
      headers: {},
      body: {
        serialID,
        readableTimestamp,
        fund,
        docType,
      },
    };

    try {
      await API.post(apiName, path, requestInfo);
      console.log("Batch logged successfully.");
    } catch (error) {
      console.error("Error logging batch:", error);
      alert("Error logging batch. Please try again later.");
    }
  };

  const onFileUpload = async () => {
    if (selectedFiles.length === 0) {
      alert("Please select files to upload.");
      return;
    }

    if (selectedOption === "Manual Filters" && (!fund || !docType)) {
      alert("Please select a fund and a document type.");
      return;
    }

    setLoading(true);

    const serialID = now.toISOString().replace(/[-:.TZ]/g, "");
    console.log("serialID", serialID);
    const readableTimestamp = now.toLocaleString();

    try {
      await logBatch(serialID, readableTimestamp, fund, docType);
    } catch (error) {
      setLoading(false);
      return;
    }

    let successfullyUploadedCount = 0;

    for (const file of selectedFiles) {
      const hasPageNumber =
        /_page\d+\.(doc|pdf|xlsx|xls|csv|txt|ppt|pptx)$/.test(file.name);

      const fileName = hasPageNumber
        ? `mergefiles/${serialID}_${file.name}`
        : `staging/${serialID}_${file.name}`;

      console.log("fileName bebop", fileName);
      try {
        await Storage.put(fileName, file, {
          contentType: file.type,
          metadata: {
            fund,
            docType,
            uploadDate: uploadDate.toISOString(),
            serialID,
          },
        });
        successfullyUploadedCount++;
      } catch (error) {
        console.error(`Error uploading ${fileName}: ${error}`);
      }
    }

    setLoading(false);
    setSelectedFiles([]);
    setUploadedFileCount(successfullyUploadedCount);

    if (successfullyUploadedCount === selectedFiles.length) {
      alert("All files uploaded successfully.");
    }
  };

  const validatePortalTag = (fileName) => {
    console.log("fileName", fileName);
    const regex = /^[a-zA-Z0-9]+\.(doc|pdf|xlsx|xls|csv|txt|ppt|pptx)$/; // Allow letters, numbers, and specified extensions
    return regex.test(fileName);
  };

  const fileData = () => {
    if (selectedFiles.length > 0) {
      return (
        <div className="table-container">
          <Table striped bordered hover variant="dark">
            <thead>
              <tr>
                <th>
                  <Form.Check
                    type="checkbox"
                    onChange={toggleSelectAll}
                    checked={
                      selectedFileIndices.length === selectedFiles.length &&
                      selectedFiles.length > 0
                    }
                  />
                </th>
                <th>File Details</th>
                <th>Valid File Name</th>
                <th>Renamed File</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {selectedFiles.map((file, index) => {
                const isValid =
                  selectedOption === "File Name Method"
                    ? validateFileName(file.name)
                    : validatePortalTag(file.name);

                const batchID = now
                  .toISOString()
                  .replace(/[-:.TZ]/g, "")
                  .substring(0, 14);

                const hasPageNumber =
                  /_page\d+\.(doc|pdf|xlsx|xls|csv|txt|ppt|pptx)$/.test(
                    file.name
                  );

                const renamedFile = hasPageNumber
                  ? `mergefiles/${batchID}_${fund || "fund"}_${
                      docType || "docType"
                    }_${formatDate(docType, uploadDate)}_${file.name}`
                  : `${batchID}_${fund || "fund"}_${
                      docType || "docType"
                    }_${formatDate(docType, uploadDate)}_${file.name}`;
                console.log("batchID:", batchID);
                console.log("fund:", fund);
                console.log("docType:", docType);
                console.log("formattedDate:", formatDate(docType, uploadDate));
                console.log("fileName:", file.name);

                return (
                  <tr key={index}>
                    <td>
                      <Form.Check
                        type="checkbox"
                        checked={selectedFileIndices.includes(index)}
                        onChange={() => toggleFileSelection(index)}
                      />
                    </td>
                    <td>
                      {file.name} ({(file.size / 1024 / 1024).toFixed(2)} MB)
                    </td>
                    <td>
                      {isValid ? (
                        <FaCheck color="green" />
                      ) : (
                        <FaTimes color="red" />
                      )}
                    </td>
                    <td>{renamedFile}</td>
                    <td>
                      <button onClick={() => removeFile(index)}>
                        <FaTrash />
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      );
    } else if (uploadedFileCount > 0) {
      return <h4>{uploadedFileCount} files uploaded successfully.</h4>;
    }
  };

  const sampleFileName =
    selectedOption === "File Name Method"
      ? `fund_docType_docDate_portalTag_page# (page# only if merging)`
      : `${fund || "fund"}_${docType || "docType"}_${formatDate(
          docType,
          uploadDate
        )}_portalTag_page# (page# only if merging)`;

  return (
    <div>
      <RibbonBar
        options={options}
        selectedOption={selectedOption}
        onOptionClick={handleOptionClick}
      />
      <Row>
        {selectedOption === "Manual Filters" && (
          <>
            <Col>
              <FundFilter placeholder="All Funds" onSelect={setFund} />
            </Col>
            <Col>
              <DropdownFilter
                options={docTypeOptions}
                onSelect={setDocType}
                placeholder="Select Document Type"
              />
            </Col>
            <Col>
              <DatePicker
                selected={uploadDate}
                onChange={(date) => setUploadDate(date)}
                placeholderText={`Nearest Quarter End: ${getNearestQuarterEnd().toDateString()}`}
              />
            </Col>
          </>
        )}
      </Row>

      {selectedOption === "Manual Filters" && (
        <div style={{ textDecoration: "underline", padding: "5px" }}>
          Sample File Name: {sampleFileName}
        </div>
      )}
      {selectedOption === "File Name Method" && (
        <div style={{ textDecoration: "underline", padding: "5px" }}>
          FILE NAME SHOULD MATCH THIS FORMAT: {sampleFileName}
        </div>
      )}
      <div
        style={{
          marginBottom: "10px",
          color: hasMergefilesPrefix ? "green" : "red",
        }}
      >
        {hasMergefilesPrefix
          ? "The mergefiles/ prefix is being applied to your files."
          : "No mergefiles/ prefix is being applied to your files (you need page numbers to merge files)."}
      </div>
      <div className="button-group">
        <label htmlFor="fileInput" className="choose-files-button button">
          Choose Files
        </label>
        <input
          type="file"
          id="fileInput"
          className="file-input"
          multiple
          onChange={onFileChange}
        />
        <button
          className="button"
          onClick={removeSelectedFiles}
          disabled={selectedFileIndices.length === 0}
        >
          Remove Selected Files
        </button>
        <button className="button" onClick={onFileUpload}>
          Upload {selectedFiles.length} file
          {selectedFiles.length !== 1 ? "s" : ""}
        </button>
      </div>
      {loading ? <Spinner animation="border" /> : fileData()}
    </div>
  );
};

export default FileUpload;
