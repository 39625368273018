import React from "react";

const ProjFlowsTemplate = ({ date }) => {
  return (
    <div>
      <p>
        There is NO ACTION that must be taken today. This is a notice of
        projected capital calls for {date} and is based on the latest
        information we have from our managers at this time.
      </p>
      <p>
        [insert commentary here] As of today, we only know of two of our
        managers who may call capital during Q4 2023: 1) Kline Hill (10T XII) –
        this manager generally calls capital quarterly to fund secondary deals
        closed during each quarter and 2) DFW VII (10T XIII) – we know DFW has
        been tracking down a few deals and we are not sure if any of those will
        close by year end, but we have opted to include an estimate for cash
        needed to be on the conservative side of what may be called from our
        limited partners in the event that a deal does close by year end.
      </p>
      <p>
        A 10Talents {date} Cash Projection Report has been posted under the
        10Talents Private Equity Program heading for you to review on our secure
        investor reporting portal. Please do not hesitate to reach out to us or
        your advisor with any questions regarding this communication.
      </p>
    </div>
  );
};

export default ProjFlowsTemplate;
