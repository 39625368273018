import React, { useState, useEffect } from "react";
// import { API, Auth } from "aws-amplify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DropdownFilter from "../DropdownFilter";
import { Row, Col, Modal, Button } from "react-bootstrap";
import { FaTrash } from "react-icons/fa";
import FundFilter from "../FundFilter";
import BatchFilter from "../BatchFilter";

// const apiName = "talentsapi";

const EmailFilters = ({
  initialFilters,
  onFilterSubmitted,
  setShowPreview,
  // templateSubject, // RECEIVING TEMPLATE SUBJECT
  // templateContent, // RECEIVING EDITABLE TEXT
  ...previews
}) => {
  const [state, setState] = useState({
    ...initialFilters,
    showPreview: false,
  });
  const [errors, setErrors] = useState({
    docTypeError: "",
    fundError: "",
  });
  const [showErrorModal, setShowErrorModal] = useState(false);

  // console.log("templateSubject:", templateSubject);
  // console.log("templateContent:", templateContent);

  const {
    startDate,
    fund,
    doc_type,
    fundLongName,
    formattedDate,
    batchID,
    batchName,
  } = state;

  // Keep track of filters and update state when initialFilters change
  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      ...initialFilters, // Update state when initial filters change
    }));
  }, [initialFilters]);

  // Handle filter changes and update parent component
  const handleDateChange = (date) => {
    const newState = { ...state, startDate: date };
    setState(newState);
    onFilterSubmitted(newState); // Update parent with new filters
  };

  const handleDocTypeFilterSelect = (selectedValue) => {
    const newState = { ...state, doc_type: selectedValue };
    setState(newState);
    onFilterSubmitted(newState); // Update parent with new filters
  };

  const handleFundFilterSelect = (selectedValue) => {
    const fundLongName = fundLongNameMap[selectedValue] || "";
    const newState = {
      ...state,
      fund: selectedValue,
      fundLongName: fundLongName,
    };
    setState(newState);
    onFilterSubmitted(newState); // Update parent with new filters
  };

  const handleBatchFilterSelect = (selectedOption) => {
    if (selectedOption) {
      const { label: batchName, value: batchID } = selectedOption;

      const newState = {
        ...state,
        batchID, // set the batchID
        batchName, // set the batchName
      };

      setState(newState);
      onFilterSubmitted(newState); // Update parent with new filters
    } else {
      // Handle the case when selection is cleared
      const newState = {
        ...state,
        batchID: null,
        batchName: null,
      };

      setState(newState);
      onFilterSubmitted(newState);
    }
  };

  const formatDate = () => {
    if (["K1Template", "Audited Year End FS"].includes(doc_type)) {
      return startDate.getFullYear().toString();
    } else if (
      ["Capital Call", "Call Reminder", "Capital Distribution"].includes(
        doc_type
      )
    ) {
      const month = startDate.getMonth() + 1; // months are zero-based
      const day = startDate.getDate();
      return `${month}/${day}`;
    } else if (
      ["Reporting Package", "CAS and FS", "Projected Flows"].includes(doc_type)
    ) {
      const quarter = Math.floor((startDate.getMonth() + 3) / 3);
      return `Q${quarter} ${startDate.getFullYear()}`;
    }
  };

  const fundLongNameMap = {
    "10Talents Coinvest (Co-Investments)":
      "10Talents Co-Investment Fund I, L.P.",
    "10Talents I (Castle Creek)": "10Talents Fund I, L.P.",
    "10Talents II (Strattam)": "10Talents Private Fund II, L.P.",
    "10Talents III (Atlas)": "10Talents Private Fund III, L.P.",
    "10Talents IV (Castle Creek)": "10Talents Private Fund IV, L.P.",
    "10Talents IX (Berkshire)": "10Talents Private Fund IX, L.P.",
    "10Talents V (DFW)": "10Talents Private Fund V, L.P.",
    "10Talents VI (Growth/Venture)": "10Talents Private Fund VI, L.P.",
    "10Talents VII (Kline Hill)": "10Talents Private Fund VII, L.P.",
    "10Talents VIII (CD&R)": "10Talents Private Fund VIII, L.P.",
    "10Talents X (Atlas)": "10Talents Private Fund X, L.P.",
    "10Talents XI (Growth/Venture)": "10Talents Private Fund XI, L.P.",
    "10Talents XII (Kline Hill)": "10Talents Private Fund XII, L.P.",
    "10Talents XIII (DFW)": "10Talents Private Fund XIII, L.P.",
    "10Talents XIV (Castle Creek)": "10Talents Private Fund XIV, L.P.",
    "10Talents XV (Growth/Venture)": "10Talents Private Fund XV, L.P.",
    "VL Blue I (AEA Investors)": "VL Blue Private Investment Fund I, L.P.",
    "VL Orange I (Jasper Ridge)": "VL Orange Private Investment Fund I, L.P.",
    "VL Orange II (Jasper Ridge)": "VL Orange Private Investment Fund II, L.P.",
  };

  const validateForm = (state) => {
    let docTypeError = "";
    let fundError = "";

    if (!state.doc_type) {
      docTypeError = "You need a doc type.";
    } else if (
      ![
        "Reporting Package",
        "CAS and FS",
        "Audited Year End FS",
        "Projected Flows",
      ].includes(state.doc_type) &&
      !state.fund
    ) {
      fundError = "You need a fund name.";
    }

    setErrors({ docTypeError, fundError });

    return !docTypeError && !fundError;
  };

  const handleRefresh = () => {
    window.location.reload();
  };

  // console.log("initiallllFilts", initialFilters);

  return (
    <div>
      <Row>
        <Col>
          <DropdownFilter
            options={[
              "Capital Call",
              "Call Reminder",
              "Capital Distribution",
              "Reporting Package",
              "CAS and FS",
              "Audited Year End FS",
              "K1Template",
              "Projected Flows",
            ]}
            placeholder={"Select a Document Type"}
            onSelect={handleDocTypeFilterSelect}
            value={doc_type}
          />
        </Col>
        <Col>
          <FundFilter
            placeholder={"Select a Fund"}
            onSelect={handleFundFilterSelect}
            value={fund}
          />
        </Col>
        <Col style={{ textAlign: "right" }}>
          <DatePicker
            selected={startDate}
            onChange={handleDateChange}
            showYearDropdown
            showMonthDropdown
            className="datepicker-input"
          />
        </Col>
        <Col>
          <button onClick={handleRefresh}>
            <FaTrash /> Reset Filters
          </button>
        </Col>
      </Row>
      <Row>
        <Col>
          <BatchFilter
            placeholder={"Select a file batch to associate"}
            onSelect={handleBatchFilterSelect}
            value={batchID}
            label={batchName}
          />
        </Col>
      </Row>

      {/* Error Modal */}
      <Modal show={showErrorModal} onHide={() => setShowErrorModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Form Error</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {errors.docTypeError && <p>{errors.docTypeError}</p>}
          {errors.fundError && <p>{errors.fundError}</p>}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowErrorModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default EmailFilters;
