/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const listAllInvestors = /* GraphQL */ `
  query ListAllInvestors {
    listAllInvestors {
      id
      investor_id
      investor_name
      batch_id
      effective_valid_from_date
      effective_valid_to_date
      system_valid_from_date
      system_valid_to_date
      is_deleted
      account_id
      account {
        id
        account_id
        account_name
        batch_id
        effective_valid_from_date
        effective_valid_to_date
        system_valid_from_date
        system_valid_to_date
        is_deleted
        __typename
      }
      __typename
    }
  }
`;
export const listAllAccounts = /* GraphQL */ `
  query ListAllAccounts {
    listAllAccounts {
      id
      account_id
      account_name
      batch_id
      effective_valid_from_date
      effective_valid_to_date
      system_valid_from_date
      system_valid_to_date
      is_deleted
      investors {
        id
        investor_id
        investor_name
        batch_id
        effective_valid_from_date
        effective_valid_to_date
        system_valid_from_date
        system_valid_to_date
        is_deleted
        account_id
        __typename
      }
      contactssss {
        id
        contact_id
        contact_name
        familiar_name
        email
        phone
        batch_id
        effective_valid_from_date
        effective_valid_to_date
        system_valid_from_date
        system_valid_to_date
        is_deleted
        account_id
        __typename
      }
      __typename
    }
  }
`;
export const listAllContacts = /* GraphQL */ `
  query ListAllContacts {
    listAllContacts {
      id
      contact_id
      contact_name
      familiar_name
      email
      phone
      batch_id
      effective_valid_from_date
      effective_valid_to_date
      system_valid_from_date
      system_valid_to_date
      is_deleted
      account_id
      doc_package_permissions {
        id
        doc_package_permission_id
        doc_package_name_id
        opportunity_id
        contact_id
        batch_id
        effective_valid_from_date
        effective_valid_to_date
        system_valid_from_date
        system_valid_to_date
        is_deleted
        __typename
      }
      account {
        id
        account_id
        account_name
        batch_id
        effective_valid_from_date
        effective_valid_to_date
        system_valid_from_date
        system_valid_to_date
        is_deleted
        __typename
      }
      __typename
    }
  }
`;
export const listAllDocPackageNames = /* GraphQL */ `
  query ListAllDocPackageNames {
    listAllDocPackageNames {
      id
      doc_package_name_id
      doc_package_name
      batch_id
      effective_valid_from_date
      effective_valid_to_date
      system_valid_from_date
      system_valid_to_date
      is_deleted
      __typename
    }
  }
`;
export const listAllDocPackagePermissions = /* GraphQL */ `
  query ListAllDocPackagePermissions {
    listAllDocPackagePermissions {
      id
      doc_package_permission_id
      doc_package_name_id
      opportunity_id
      contact_id
      batch_id
      effective_valid_from_date
      effective_valid_to_date
      system_valid_from_date
      system_valid_to_date
      is_deleted
      doc_package_name {
        id
        doc_package_name_id
        doc_package_name
        batch_id
        effective_valid_from_date
        effective_valid_to_date
        system_valid_from_date
        system_valid_to_date
        is_deleted
        __typename
      }
      opportunity {
        id
        feeder_fund_id
        opportunity_id
        investor_id
        batch_id
        effective_valid_from_date
        effective_valid_to_date
        system_valid_from_date
        system_valid_to_date
        is_deleted
        opportunity_name
        __typename
      }
      contact {
        id
        contact_id
        contact_name
        familiar_name
        email
        phone
        batch_id
        effective_valid_from_date
        effective_valid_to_date
        system_valid_from_date
        system_valid_to_date
        is_deleted
        account_id
        __typename
      }
      filepaths {
        id
        fund
        doc_type
        filepath
        presignedUrl
        is_deleted
        __typename
      }
      __typename
    }
  }
`;
export const listAllOpportunities = /* GraphQL */ `
  query ListAllOpportunities {
    listAllOpportunities {
      id
      feeder_fund_id
      opportunity_id
      investor_id
      batch_id
      effective_valid_from_date
      effective_valid_to_date
      system_valid_from_date
      system_valid_to_date
      is_deleted
      opportunity_name
      feeder_fund {
        id
        feeder_fund_id
        feeder_fund_name
        feeder_fund_long_name
        fund_number
        feeder_strategy
        feeder_industry
        batch_id
        effective_valid_from_date
        effective_valid_to_date
        system_valid_from_date
        system_valid_to_date
        is_deleted
        __typename
      }
      investor {
        id
        investor_id
        investor_name
        batch_id
        effective_valid_from_date
        effective_valid_to_date
        system_valid_from_date
        system_valid_to_date
        is_deleted
        account_id
        __typename
      }
      __typename
    }
  }
`;
export const listAllFeederFunds = /* GraphQL */ `
  query ListAllFeederFunds {
    listAllFeederFunds {
      id
      feeder_fund_id
      feeder_fund_name
      feeder_fund_long_name
      fund_number
      feeder_strategy
      feeder_industry
      batch_id
      effective_valid_from_date
      effective_valid_to_date
      system_valid_from_date
      system_valid_to_date
      is_deleted
      __typename
    }
  }
`;
export const listAllS3URLs = /* GraphQL */ `
  query ListAllS3URLs {
    listAllS3URLs {
      id
      fund
      doc_type
      filepath
      presignedUrl
      is_deleted
      __typename
    }
  }
`;
export const listInvestors = /* GraphQL */ `
  query ListInvestors($account_id: ID) {
    listInvestors(account_id: $account_id) {
      id
      investor_id
      investor_name
      batch_id
      effective_valid_from_date
      effective_valid_to_date
      system_valid_from_date
      system_valid_to_date
      is_deleted
      account_id
      account {
        id
        account_id
        account_name
        batch_id
        effective_valid_from_date
        effective_valid_to_date
        system_valid_from_date
        system_valid_to_date
        is_deleted
        __typename
      }
      __typename
    }
  }
`;
export const listAccounts = /* GraphQL */ `
  query ListAccounts($account_id: ID) {
    listAccounts(account_id: $account_id) {
      id
      account_id
      account_name
      batch_id
      effective_valid_from_date
      effective_valid_to_date
      system_valid_from_date
      system_valid_to_date
      is_deleted
      investors {
        id
        investor_id
        investor_name
        batch_id
        effective_valid_from_date
        effective_valid_to_date
        system_valid_from_date
        system_valid_to_date
        is_deleted
        account_id
        __typename
      }
      contactssss {
        id
        contact_id
        contact_name
        familiar_name
        email
        phone
        batch_id
        effective_valid_from_date
        effective_valid_to_date
        system_valid_from_date
        system_valid_to_date
        is_deleted
        account_id
        __typename
      }
      __typename
    }
  }
`;
export const listContacts = /* GraphQL */ `
  query ListContacts($account_id: ID) {
    listContacts(account_id: $account_id) {
      id
      contact_id
      contact_name
      familiar_name
      email
      phone
      batch_id
      effective_valid_from_date
      effective_valid_to_date
      system_valid_from_date
      system_valid_to_date
      is_deleted
      account_id
      doc_package_permissions {
        id
        doc_package_permission_id
        doc_package_name_id
        opportunity_id
        contact_id
        batch_id
        effective_valid_from_date
        effective_valid_to_date
        system_valid_from_date
        system_valid_to_date
        is_deleted
        __typename
      }
      account {
        id
        account_id
        account_name
        batch_id
        effective_valid_from_date
        effective_valid_to_date
        system_valid_from_date
        system_valid_to_date
        is_deleted
        __typename
      }
      __typename
    }
  }
`;
export const listDocPackageNames = /* GraphQL */ `
  query ListDocPackageNames($doc_package_name_id: ID) {
    listDocPackageNames(doc_package_name_id: $doc_package_name_id) {
      id
      doc_package_name_id
      doc_package_name
      batch_id
      effective_valid_from_date
      effective_valid_to_date
      system_valid_from_date
      system_valid_to_date
      is_deleted
      __typename
    }
  }
`;
export const listDocPackagePermissions = /* GraphQL */ `
  query ListDocPackagePermissions($doc_package_permission_id: ID) {
    listDocPackagePermissions(
      doc_package_permission_id: $doc_package_permission_id
    ) {
      id
      doc_package_permission_id
      doc_package_name_id
      opportunity_id
      contact_id
      batch_id
      effective_valid_from_date
      effective_valid_to_date
      system_valid_from_date
      system_valid_to_date
      is_deleted
      doc_package_name {
        id
        doc_package_name_id
        doc_package_name
        batch_id
        effective_valid_from_date
        effective_valid_to_date
        system_valid_from_date
        system_valid_to_date
        is_deleted
        __typename
      }
      opportunity {
        id
        feeder_fund_id
        opportunity_id
        investor_id
        batch_id
        effective_valid_from_date
        effective_valid_to_date
        system_valid_from_date
        system_valid_to_date
        is_deleted
        opportunity_name
        __typename
      }
      contact {
        id
        contact_id
        contact_name
        familiar_name
        email
        phone
        batch_id
        effective_valid_from_date
        effective_valid_to_date
        system_valid_from_date
        system_valid_to_date
        is_deleted
        account_id
        __typename
      }
      filepaths {
        id
        fund
        doc_type
        filepath
        presignedUrl
        is_deleted
        __typename
      }
      __typename
    }
  }
`;
export const listOpportunities = /* GraphQL */ `
  query ListOpportunities($opportunity_id: ID) {
    listOpportunities(opportunity_id: $opportunity_id) {
      id
      feeder_fund_id
      opportunity_id
      investor_id
      batch_id
      effective_valid_from_date
      effective_valid_to_date
      system_valid_from_date
      system_valid_to_date
      is_deleted
      opportunity_name
      feeder_fund {
        id
        feeder_fund_id
        feeder_fund_name
        feeder_fund_long_name
        fund_number
        feeder_strategy
        feeder_industry
        batch_id
        effective_valid_from_date
        effective_valid_to_date
        system_valid_from_date
        system_valid_to_date
        is_deleted
        __typename
      }
      investor {
        id
        investor_id
        investor_name
        batch_id
        effective_valid_from_date
        effective_valid_to_date
        system_valid_from_date
        system_valid_to_date
        is_deleted
        account_id
        __typename
      }
      __typename
    }
  }
`;
export const listFeederFunds = /* GraphQL */ `
  query ListFeederFunds {
    listFeederFunds {
      id
      feeder_fund_id
      feeder_fund_name
      feeder_fund_long_name
      fund_number
      feeder_strategy
      feeder_industry
      batch_id
      effective_valid_from_date
      effective_valid_to_date
      system_valid_from_date
      system_valid_to_date
      is_deleted
      __typename
    }
  }
`;
export const listS3URLs = /* GraphQL */ `
  query ListS3URLs($contact_id: ID) {
    listS3URLs(contact_id: $contact_id) {
      id
      fund
      doc_type
      filepath
      presignedUrl
      is_deleted
      __typename
    }
  }
`;
export const listUserInfo = /* GraphQL */ `
  query ListUserInfo($contact_id: ID) {
    listUserInfo(contact_id: $contact_id) {
      id
      contact_id
      contact_name
      familiar_name
      email
      phone
      batch_id
      effective_valid_from_date
      effective_valid_to_date
      system_valid_from_date
      system_valid_to_date
      is_deleted
      account_id
      doc_package_permissions {
        id
        doc_package_permission_id
        doc_package_name_id
        opportunity_id
        contact_id
        batch_id
        effective_valid_from_date
        effective_valid_to_date
        system_valid_from_date
        system_valid_to_date
        is_deleted
        __typename
      }
      account {
        id
        account_id
        account_name
        batch_id
        effective_valid_from_date
        effective_valid_to_date
        system_valid_from_date
        system_valid_to_date
        is_deleted
        __typename
      }
      __typename
    }
  }
`;
export const listInvestorInfo = /* GraphQL */ `
  query ListInvestorInfo($account_id: ID) {
    listInvestorInfo(account_id: $account_id) {
      id
      investor_id
      investor_name
      batch_id
      effective_valid_from_date
      effective_valid_to_date
      system_valid_from_date
      system_valid_to_date
      is_deleted
      account_id
      account {
        id
        account_id
        account_name
        batch_id
        effective_valid_from_date
        effective_valid_to_date
        system_valid_from_date
        system_valid_to_date
        is_deleted
        __typename
      }
      __typename
    }
  }
`;
