// menuConfig.js
import {
  FaHome,
  FaFileUpload,
  FaFileAlt,
  FaEnvelope,
  FaAddressBook,
  FaCog,
} from "react-icons/fa";

export const clientMenu = [
  { name: "Home", path: "/home", icon: FaHome },
  { name: "Fund Explorer", path: "/fund-explorer", icon: FaFileAlt },
  { name: "Documents", path: "/files/view", icon: FaFileAlt },
  // { name: "Forecast", path: "/forecast", icon: FaFileAlt },
  // { name: "Settings", path: "/settings", icon: FaCog },
];

export const adminMenu = [
  { name: "Home", path: "/home", icon: FaHome },
  {
    name: "Files",
    icon: FaFileAlt,
    subItems: [
      { name: "File Upload", path: "/files/upload", icon: FaFileUpload },
      { name: "File Viewer", path: "/files/view", icon: FaFileAlt },
    ],
  },
  {
    name: "Emails",
    icon: FaEnvelope,
    subItems: [
      { name: "Email Wizard", path: "/email/wizard", icon: FaEnvelope },
      { name: "Email Audit", path: "/email/audit", icon: FaEnvelope },
    ],
  },
  { name: "CRM", path: "/CRM", icon: FaAddressBook },
  // { name: "Settings", path: "/settings", icon: FaCog },
];
