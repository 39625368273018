import React, { useEffect, useState } from "react";
import { API } from "aws-amplify";
import GenericTable from "../CRUD Tables/GenericTable";
import "bootstrap/dist/css/bootstrap.min.css";

const apiName = "talentsapi";

const fetchBatchFiles = async (
  token,
  fund,
  doc_type,
  startDate,
  endDate,
  setBatchFiles,
  setLoading
) => {
  if (token) {
    setLoading(true);
    const path = "/viewBatches";
    const requestInfo = {
      headers: {
        Authorization: token,
      },
      queryStringParameters: {},
    };

    if (fund) {
      requestInfo.queryStringParameters.Fund = fund;
    }

    if (doc_type) {
      requestInfo.queryStringParameters.DocType = doc_type;
    }

    const convertToISODate = (date) => date.toISOString().split("T")[0];

    if (startDate) {
      requestInfo.queryStringParameters.startDate = convertToISODate(startDate);
    }

    if (endDate) {
      requestInfo.queryStringParameters.endDate = convertToISODate(endDate);
    }

    try {
      const response = await API.get(apiName, path, requestInfo);
      setBatchFiles(response.items || []); // Assuming 'items' contains the batch files array
    } catch (error) {
      console.error("Error fetching batch files:", error);
    } finally {
      setLoading(false);
    }
  }
};

const BatchFileTable2 = (props) => {
  const {
    token,
    fund,
    doc_type,
    startDate,
    endDate,
    onSerialIDSelect,
    serialIDArray,
  } = props;

  const [batchFiles, setBatchFiles] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (token) {
      fetchBatchFiles(
        token,
        fund,
        doc_type,
        startDate,
        endDate,
        setBatchFiles,
        setLoading
      );
    }
  }, [token, fund, doc_type, startDate, endDate]);

  // This function will be triggered when a row is selected
  const handleRowSelect = (serialID) => {
    if (onSerialIDSelect) {
      onSerialIDSelect(serialID);
    }
  };

  const headers = batchFiles.length > 0 ? Object.keys(batchFiles[0]) : [];

  const filterableColumns = ["fund", "docType"];

  // Define the state of each action
  const actionStates = {
    download: false, // Disable download
    create: true, // Allow creation
    delete: true, // Allow delete
    update: false, // Allow update
  };

  return (
    <div>
      <h1 style={{ color: "var(--text-color)" }}>Batches</h1>
      <GenericTable
        data={batchFiles}
        loading={loading}
        headers={headers}
        keyField="serialID" // Specify the key field as 'serialID'
        onRowSelect={handleRowSelect} // Pass the row selection handler
        filterableColumns={filterableColumns} // Pass the row selection handler
        actionStates={actionStates}
        serialID={serialIDArray}
        dropdownContext="batch" // Identifier for batch context
      />
    </div>
  );
};

export default BatchFileTable2;
