/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createInvestor = /* GraphQL */ `
  mutation CreateInvestor($input: CreateInvestorInput) {
    createInvestor(input: $input) {
      id
      investor_id
      investor_name
      batch_id
      effective_valid_from_date
      effective_valid_to_date
      system_valid_from_date
      system_valid_to_date
      is_deleted
      account_id
      account {
        id
        account_id
        account_name
        batch_id
        effective_valid_from_date
        effective_valid_to_date
        system_valid_from_date
        system_valid_to_date
        is_deleted
        __typename
      }
      __typename
    }
  }
`;
export const updateInvestor = /* GraphQL */ `
  mutation UpdateInvestor($input: UpdateInvestorInput) {
    updateInvestor(input: $input) {
      id
      investor_id
      investor_name
      batch_id
      effective_valid_from_date
      effective_valid_to_date
      system_valid_from_date
      system_valid_to_date
      is_deleted
      account_id
      account {
        id
        account_id
        account_name
        batch_id
        effective_valid_from_date
        effective_valid_to_date
        system_valid_from_date
        system_valid_to_date
        is_deleted
        __typename
      }
      __typename
    }
  }
`;
export const deleteInvestor = /* GraphQL */ `
  mutation DeleteInvestor($input: UpdateInvestorInput) {
    deleteInvestor(input: $input) {
      id
      investor_id
      investor_name
      batch_id
      effective_valid_from_date
      effective_valid_to_date
      system_valid_from_date
      system_valid_to_date
      is_deleted
      account_id
      account {
        id
        account_id
        account_name
        batch_id
        effective_valid_from_date
        effective_valid_to_date
        system_valid_from_date
        system_valid_to_date
        is_deleted
        __typename
      }
      __typename
    }
  }
`;
export const restoreInvestor = /* GraphQL */ `
  mutation RestoreInvestor($input: RestoreInvestorInput) {
    restoreInvestor(input: $input) {
      id
      investor_id
      investor_name
      batch_id
      effective_valid_from_date
      effective_valid_to_date
      system_valid_from_date
      system_valid_to_date
      is_deleted
      account_id
      account {
        id
        account_id
        account_name
        batch_id
        effective_valid_from_date
        effective_valid_to_date
        system_valid_from_date
        system_valid_to_date
        is_deleted
        __typename
      }
      __typename
    }
  }
`;
export const createAccount = /* GraphQL */ `
  mutation CreateAccount($input: CreateAccountInput) {
    createAccount(input: $input) {
      id
      account_id
      account_name
      batch_id
      effective_valid_from_date
      effective_valid_to_date
      system_valid_from_date
      system_valid_to_date
      is_deleted
      investors {
        id
        investor_id
        investor_name
        batch_id
        effective_valid_from_date
        effective_valid_to_date
        system_valid_from_date
        system_valid_to_date
        is_deleted
        account_id
        __typename
      }
      contactssss {
        id
        contact_id
        contact_name
        familiar_name
        email
        phone
        batch_id
        effective_valid_from_date
        effective_valid_to_date
        system_valid_from_date
        system_valid_to_date
        is_deleted
        account_id
        __typename
      }
      __typename
    }
  }
`;
export const updateAccount = /* GraphQL */ `
  mutation UpdateAccount($input: UpdateAccountInput) {
    updateAccount(input: $input) {
      id
      account_id
      account_name
      batch_id
      effective_valid_from_date
      effective_valid_to_date
      system_valid_from_date
      system_valid_to_date
      is_deleted
      investors {
        id
        investor_id
        investor_name
        batch_id
        effective_valid_from_date
        effective_valid_to_date
        system_valid_from_date
        system_valid_to_date
        is_deleted
        account_id
        __typename
      }
      contactssss {
        id
        contact_id
        contact_name
        familiar_name
        email
        phone
        batch_id
        effective_valid_from_date
        effective_valid_to_date
        system_valid_from_date
        system_valid_to_date
        is_deleted
        account_id
        __typename
      }
      __typename
    }
  }
`;
export const deleteAccount = /* GraphQL */ `
  mutation DeleteAccount($input: UpdateAccountInput) {
    deleteAccount(input: $input) {
      id
      account_id
      account_name
      batch_id
      effective_valid_from_date
      effective_valid_to_date
      system_valid_from_date
      system_valid_to_date
      is_deleted
      investors {
        id
        investor_id
        investor_name
        batch_id
        effective_valid_from_date
        effective_valid_to_date
        system_valid_from_date
        system_valid_to_date
        is_deleted
        account_id
        __typename
      }
      contactssss {
        id
        contact_id
        contact_name
        familiar_name
        email
        phone
        batch_id
        effective_valid_from_date
        effective_valid_to_date
        system_valid_from_date
        system_valid_to_date
        is_deleted
        account_id
        __typename
      }
      __typename
    }
  }
`;
export const restoreAccount = /* GraphQL */ `
  mutation RestoreAccount($input: RestoreAccountInput) {
    restoreAccount(input: $input) {
      id
      account_id
      account_name
      batch_id
      effective_valid_from_date
      effective_valid_to_date
      system_valid_from_date
      system_valid_to_date
      is_deleted
      investors {
        id
        investor_id
        investor_name
        batch_id
        effective_valid_from_date
        effective_valid_to_date
        system_valid_from_date
        system_valid_to_date
        is_deleted
        account_id
        __typename
      }
      contactssss {
        id
        contact_id
        contact_name
        familiar_name
        email
        phone
        batch_id
        effective_valid_from_date
        effective_valid_to_date
        system_valid_from_date
        system_valid_to_date
        is_deleted
        account_id
        __typename
      }
      __typename
    }
  }
`;
export const createContact = /* GraphQL */ `
  mutation CreateContact($input: CreateContactInput) {
    createContact(input: $input) {
      id
      contact_id
      contact_name
      familiar_name
      email
      phone
      batch_id
      effective_valid_from_date
      effective_valid_to_date
      system_valid_from_date
      system_valid_to_date
      is_deleted
      account_id
      doc_package_permissions {
        id
        doc_package_permission_id
        doc_package_name_id
        opportunity_id
        contact_id
        batch_id
        effective_valid_from_date
        effective_valid_to_date
        system_valid_from_date
        system_valid_to_date
        is_deleted
        __typename
      }
      account {
        id
        account_id
        account_name
        batch_id
        effective_valid_from_date
        effective_valid_to_date
        system_valid_from_date
        system_valid_to_date
        is_deleted
        __typename
      }
      __typename
    }
  }
`;
export const updateContact = /* GraphQL */ `
  mutation UpdateContact($input: UpdateContactInput) {
    updateContact(input: $input) {
      id
      contact_id
      contact_name
      familiar_name
      email
      phone
      batch_id
      effective_valid_from_date
      effective_valid_to_date
      system_valid_from_date
      system_valid_to_date
      is_deleted
      account_id
      doc_package_permissions {
        id
        doc_package_permission_id
        doc_package_name_id
        opportunity_id
        contact_id
        batch_id
        effective_valid_from_date
        effective_valid_to_date
        system_valid_from_date
        system_valid_to_date
        is_deleted
        __typename
      }
      account {
        id
        account_id
        account_name
        batch_id
        effective_valid_from_date
        effective_valid_to_date
        system_valid_from_date
        system_valid_to_date
        is_deleted
        __typename
      }
      __typename
    }
  }
`;
export const deleteContact = /* GraphQL */ `
  mutation DeleteContact($input: UpdateContactInput) {
    deleteContact(input: $input) {
      id
      contact_id
      contact_name
      familiar_name
      email
      phone
      batch_id
      effective_valid_from_date
      effective_valid_to_date
      system_valid_from_date
      system_valid_to_date
      is_deleted
      account_id
      doc_package_permissions {
        id
        doc_package_permission_id
        doc_package_name_id
        opportunity_id
        contact_id
        batch_id
        effective_valid_from_date
        effective_valid_to_date
        system_valid_from_date
        system_valid_to_date
        is_deleted
        __typename
      }
      account {
        id
        account_id
        account_name
        batch_id
        effective_valid_from_date
        effective_valid_to_date
        system_valid_from_date
        system_valid_to_date
        is_deleted
        __typename
      }
      __typename
    }
  }
`;
export const restoreContact = /* GraphQL */ `
  mutation RestoreContact($input: RestoreContactInput) {
    restoreContact(input: $input) {
      id
      contact_id
      contact_name
      familiar_name
      email
      phone
      batch_id
      effective_valid_from_date
      effective_valid_to_date
      system_valid_from_date
      system_valid_to_date
      is_deleted
      account_id
      doc_package_permissions {
        id
        doc_package_permission_id
        doc_package_name_id
        opportunity_id
        contact_id
        batch_id
        effective_valid_from_date
        effective_valid_to_date
        system_valid_from_date
        system_valid_to_date
        is_deleted
        __typename
      }
      account {
        id
        account_id
        account_name
        batch_id
        effective_valid_from_date
        effective_valid_to_date
        system_valid_from_date
        system_valid_to_date
        is_deleted
        __typename
      }
      __typename
    }
  }
`;
export const createDocPackageName = /* GraphQL */ `
  mutation CreateDocPackageName($input: CreateDocPackageNameInput) {
    createDocPackageName(input: $input) {
      id
      doc_package_name_id
      doc_package_name
      batch_id
      effective_valid_from_date
      effective_valid_to_date
      system_valid_from_date
      system_valid_to_date
      is_deleted
      __typename
    }
  }
`;
export const updateDocPackageName = /* GraphQL */ `
  mutation UpdateDocPackageName($input: UpdateDocPackageNameInput) {
    updateDocPackageName(input: $input) {
      id
      doc_package_name_id
      doc_package_name
      batch_id
      effective_valid_from_date
      effective_valid_to_date
      system_valid_from_date
      system_valid_to_date
      is_deleted
      __typename
    }
  }
`;
export const deleteDocPackageName = /* GraphQL */ `
  mutation DeleteDocPackageName($input: UpdateDocPackageNameInput) {
    deleteDocPackageName(input: $input) {
      id
      doc_package_name_id
      doc_package_name
      batch_id
      effective_valid_from_date
      effective_valid_to_date
      system_valid_from_date
      system_valid_to_date
      is_deleted
      __typename
    }
  }
`;
export const restoreDocPackageName = /* GraphQL */ `
  mutation RestoreDocPackageName($input: RestoreDocPackageNameInput) {
    restoreDocPackageName(input: $input) {
      id
      doc_package_name_id
      doc_package_name
      batch_id
      effective_valid_from_date
      effective_valid_to_date
      system_valid_from_date
      system_valid_to_date
      is_deleted
      __typename
    }
  }
`;
export const createDocPackagePermission = /* GraphQL */ `
  mutation CreateDocPackagePermission($input: CreateDocPackagePermissionInput) {
    createDocPackagePermission(input: $input) {
      id
      doc_package_permission_id
      doc_package_name_id
      opportunity_id
      contact_id
      batch_id
      effective_valid_from_date
      effective_valid_to_date
      system_valid_from_date
      system_valid_to_date
      is_deleted
      doc_package_name {
        id
        doc_package_name_id
        doc_package_name
        batch_id
        effective_valid_from_date
        effective_valid_to_date
        system_valid_from_date
        system_valid_to_date
        is_deleted
        __typename
      }
      opportunity {
        id
        feeder_fund_id
        opportunity_id
        investor_id
        batch_id
        effective_valid_from_date
        effective_valid_to_date
        system_valid_from_date
        system_valid_to_date
        is_deleted
        opportunity_name
        __typename
      }
      contact {
        id
        contact_id
        contact_name
        familiar_name
        email
        phone
        batch_id
        effective_valid_from_date
        effective_valid_to_date
        system_valid_from_date
        system_valid_to_date
        is_deleted
        account_id
        __typename
      }
      filepaths {
        id
        fund
        doc_type
        filepath
        presignedUrl
        is_deleted
        __typename
      }
      __typename
    }
  }
`;
export const updateDocPackagePermission = /* GraphQL */ `
  mutation UpdateDocPackagePermission($input: UpdateDocPackagePermissionInput) {
    updateDocPackagePermission(input: $input) {
      id
      doc_package_permission_id
      doc_package_name_id
      opportunity_id
      contact_id
      batch_id
      effective_valid_from_date
      effective_valid_to_date
      system_valid_from_date
      system_valid_to_date
      is_deleted
      doc_package_name {
        id
        doc_package_name_id
        doc_package_name
        batch_id
        effective_valid_from_date
        effective_valid_to_date
        system_valid_from_date
        system_valid_to_date
        is_deleted
        __typename
      }
      opportunity {
        id
        feeder_fund_id
        opportunity_id
        investor_id
        batch_id
        effective_valid_from_date
        effective_valid_to_date
        system_valid_from_date
        system_valid_to_date
        is_deleted
        opportunity_name
        __typename
      }
      contact {
        id
        contact_id
        contact_name
        familiar_name
        email
        phone
        batch_id
        effective_valid_from_date
        effective_valid_to_date
        system_valid_from_date
        system_valid_to_date
        is_deleted
        account_id
        __typename
      }
      filepaths {
        id
        fund
        doc_type
        filepath
        presignedUrl
        is_deleted
        __typename
      }
      __typename
    }
  }
`;
export const deleteDocPackagePermission = /* GraphQL */ `
  mutation DeleteDocPackagePermission($input: UpdateDocPackagePermissionInput) {
    deleteDocPackagePermission(input: $input) {
      id
      doc_package_permission_id
      doc_package_name_id
      opportunity_id
      contact_id
      batch_id
      effective_valid_from_date
      effective_valid_to_date
      system_valid_from_date
      system_valid_to_date
      is_deleted
      doc_package_name {
        id
        doc_package_name_id
        doc_package_name
        batch_id
        effective_valid_from_date
        effective_valid_to_date
        system_valid_from_date
        system_valid_to_date
        is_deleted
        __typename
      }
      opportunity {
        id
        feeder_fund_id
        opportunity_id
        investor_id
        batch_id
        effective_valid_from_date
        effective_valid_to_date
        system_valid_from_date
        system_valid_to_date
        is_deleted
        opportunity_name
        __typename
      }
      contact {
        id
        contact_id
        contact_name
        familiar_name
        email
        phone
        batch_id
        effective_valid_from_date
        effective_valid_to_date
        system_valid_from_date
        system_valid_to_date
        is_deleted
        account_id
        __typename
      }
      filepaths {
        id
        fund
        doc_type
        filepath
        presignedUrl
        is_deleted
        __typename
      }
      __typename
    }
  }
`;
export const restoreDocPackagePermission = /* GraphQL */ `
  mutation RestoreDocPackagePermission(
    $input: RestoreDocPackagePermissionInput
  ) {
    restoreDocPackagePermission(input: $input) {
      id
      doc_package_permission_id
      doc_package_name_id
      opportunity_id
      contact_id
      batch_id
      effective_valid_from_date
      effective_valid_to_date
      system_valid_from_date
      system_valid_to_date
      is_deleted
      doc_package_name {
        id
        doc_package_name_id
        doc_package_name
        batch_id
        effective_valid_from_date
        effective_valid_to_date
        system_valid_from_date
        system_valid_to_date
        is_deleted
        __typename
      }
      opportunity {
        id
        feeder_fund_id
        opportunity_id
        investor_id
        batch_id
        effective_valid_from_date
        effective_valid_to_date
        system_valid_from_date
        system_valid_to_date
        is_deleted
        opportunity_name
        __typename
      }
      contact {
        id
        contact_id
        contact_name
        familiar_name
        email
        phone
        batch_id
        effective_valid_from_date
        effective_valid_to_date
        system_valid_from_date
        system_valid_to_date
        is_deleted
        account_id
        __typename
      }
      filepaths {
        id
        fund
        doc_type
        filepath
        presignedUrl
        is_deleted
        __typename
      }
      __typename
    }
  }
`;
export const createOpportunity = /* GraphQL */ `
  mutation CreateOpportunity($input: CreateOpportunityInput) {
    createOpportunity(input: $input) {
      id
      feeder_fund_id
      opportunity_id
      investor_id
      batch_id
      effective_valid_from_date
      effective_valid_to_date
      system_valid_from_date
      system_valid_to_date
      is_deleted
      opportunity_name
      feeder_fund {
        id
        feeder_fund_id
        feeder_fund_name
        feeder_fund_long_name
        fund_number
        feeder_strategy
        feeder_industry
        batch_id
        effective_valid_from_date
        effective_valid_to_date
        system_valid_from_date
        system_valid_to_date
        is_deleted
        __typename
      }
      investor {
        id
        investor_id
        investor_name
        batch_id
        effective_valid_from_date
        effective_valid_to_date
        system_valid_from_date
        system_valid_to_date
        is_deleted
        account_id
        __typename
      }
      __typename
    }
  }
`;
export const updateOpportunity = /* GraphQL */ `
  mutation UpdateOpportunity($input: UpdateOpportunityInput) {
    updateOpportunity(input: $input) {
      id
      feeder_fund_id
      opportunity_id
      investor_id
      batch_id
      effective_valid_from_date
      effective_valid_to_date
      system_valid_from_date
      system_valid_to_date
      is_deleted
      opportunity_name
      feeder_fund {
        id
        feeder_fund_id
        feeder_fund_name
        feeder_fund_long_name
        fund_number
        feeder_strategy
        feeder_industry
        batch_id
        effective_valid_from_date
        effective_valid_to_date
        system_valid_from_date
        system_valid_to_date
        is_deleted
        __typename
      }
      investor {
        id
        investor_id
        investor_name
        batch_id
        effective_valid_from_date
        effective_valid_to_date
        system_valid_from_date
        system_valid_to_date
        is_deleted
        account_id
        __typename
      }
      __typename
    }
  }
`;
export const deleteOpportunity = /* GraphQL */ `
  mutation DeleteOpportunity($input: UpdateOpportunityInput) {
    deleteOpportunity(input: $input) {
      id
      feeder_fund_id
      opportunity_id
      investor_id
      batch_id
      effective_valid_from_date
      effective_valid_to_date
      system_valid_from_date
      system_valid_to_date
      is_deleted
      opportunity_name
      feeder_fund {
        id
        feeder_fund_id
        feeder_fund_name
        feeder_fund_long_name
        fund_number
        feeder_strategy
        feeder_industry
        batch_id
        effective_valid_from_date
        effective_valid_to_date
        system_valid_from_date
        system_valid_to_date
        is_deleted
        __typename
      }
      investor {
        id
        investor_id
        investor_name
        batch_id
        effective_valid_from_date
        effective_valid_to_date
        system_valid_from_date
        system_valid_to_date
        is_deleted
        account_id
        __typename
      }
      __typename
    }
  }
`;
export const restoreOpportunity = /* GraphQL */ `
  mutation RestoreOpportunity($input: RestoreOpportunityInput) {
    restoreOpportunity(input: $input) {
      id
      feeder_fund_id
      opportunity_id
      investor_id
      batch_id
      effective_valid_from_date
      effective_valid_to_date
      system_valid_from_date
      system_valid_to_date
      is_deleted
      opportunity_name
      feeder_fund {
        id
        feeder_fund_id
        feeder_fund_name
        feeder_fund_long_name
        fund_number
        feeder_strategy
        feeder_industry
        batch_id
        effective_valid_from_date
        effective_valid_to_date
        system_valid_from_date
        system_valid_to_date
        is_deleted
        __typename
      }
      investor {
        id
        investor_id
        investor_name
        batch_id
        effective_valid_from_date
        effective_valid_to_date
        system_valid_from_date
        system_valid_to_date
        is_deleted
        account_id
        __typename
      }
      __typename
    }
  }
`;
export const createFeederFund = /* GraphQL */ `
  mutation CreateFeederFund($input: CreateFeederFundInput) {
    createFeederFund(input: $input) {
      id
      feeder_fund_id
      feeder_fund_name
      feeder_fund_long_name
      fund_number
      feeder_strategy
      feeder_industry
      batch_id
      effective_valid_from_date
      effective_valid_to_date
      system_valid_from_date
      system_valid_to_date
      is_deleted
      __typename
    }
  }
`;
export const updateFeederFund = /* GraphQL */ `
  mutation UpdateFeederFund($input: UpdateFeederFundInput) {
    updateFeederFund(input: $input) {
      id
      feeder_fund_id
      feeder_fund_name
      feeder_fund_long_name
      fund_number
      feeder_strategy
      feeder_industry
      batch_id
      effective_valid_from_date
      effective_valid_to_date
      system_valid_from_date
      system_valid_to_date
      is_deleted
      __typename
    }
  }
`;
export const deleteFeederFund = /* GraphQL */ `
  mutation DeleteFeederFund($input: UpdateFeederFundInput) {
    deleteFeederFund(input: $input) {
      id
      feeder_fund_id
      feeder_fund_name
      feeder_fund_long_name
      fund_number
      feeder_strategy
      feeder_industry
      batch_id
      effective_valid_from_date
      effective_valid_to_date
      system_valid_from_date
      system_valid_to_date
      is_deleted
      __typename
    }
  }
`;
export const restoreFeederFund = /* GraphQL */ `
  mutation RestoreFeederFund($input: RestoreFeederFundInput) {
    restoreFeederFund(input: $input) {
      id
      feeder_fund_id
      feeder_fund_name
      feeder_fund_long_name
      fund_number
      feeder_strategy
      feeder_industry
      batch_id
      effective_valid_from_date
      effective_valid_to_date
      system_valid_from_date
      system_valid_to_date
      is_deleted
      __typename
    }
  }
`;
