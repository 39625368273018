import React from "react";

const CapCallTemplate = ({ date, fundLongName }) => {
  const dateStr = date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
  const dayOfWeek = date.getDay();

  const weekdays = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  return (
    <div>
      <p>
        A{" "}
        <span style={{ fontWeight: "bold", textDecoration: "underline" }}>
          CAPITAL CALL NOTICE
        </span>{" "}
        for {fundLongName} is available for your review on our secure investor
        reporting portal.
      </p>
      <p style={{ fontWeight: "bold", textDecoration: "underline" }}>
        This capital call is due by {weekdays[dayOfWeek]}, {dateStr}.
      </p>
    </div>
  );
};

export default CapCallTemplate;
