import React, { useState, useEffect } from "react";
import { API, Auth } from "aws-amplify";
import { Table, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { FaEnvelope, FaSort, FaPlus, FaMinus } from "react-icons/fa";

const apiName = "talentsapi";

const EmailList = ({ filters, setSelectedFinalEmails }) => {
  const [emailList, setEmailList] = useState([]);
  const [selectedEmails, setSelectedEmails] = useState([]);
  const [expandedRows, setExpandedRows] = useState([]);
  const [token, setToken] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [selectAll, setSelectAll] = useState(false); // State for Select All
  const navigate = useNavigate();

  useEffect(() => {
    const fetchToken = async () => {
      const session = await Auth.currentSession();
      const jwtToken = session.getIdToken().getJwtToken();
      setToken(jwtToken);
    };

    fetchToken();
  }, []);

  useEffect(() => {
    const getEmailList = async () => {
      const path = "/emailList";

      try {
        const docTypeMap = {
          "Reporting Package": "Quarterly Investor Report - QLP",
          "Capital Call": "Capital Calls - CCL",
          "Call Reminder": "Capital Calls - CCL",
          "Capital Distribution": "Distribution Notices - DNL",
          "CAS and FS": "Capital Account Statement - CAS",
          "Audited Year End FS": "Financial Statements - QFS",
          K1Template: "Tax Documents - KTD",
          "Projected Flows": "Capital Calls - CCL",
        };

        const fundLongNameMap = {
          "10Talents I (Castle Creek)": "10Talents I",
          "10Talents II (Strattam)": "10Talents II",
          "10Talents III (Atlas)": "10Talents III",
          "10Talents IV (Castle Creek)": "10Talents IV",
          "10Talents IX (Berkshire)": "10Talents IX",
          "10Talents V (DFW)": "10Talents V",
          "10Talents VI (Growth/Venture)": "10Talents VI",
          "10Talents VII (Kline Hill)": "10Talents VII",
          "10Talents VIII (CD&R)": "10Talents VIII",
          "10Talents X (Atlas)": "10Talents X",
          "10Talents XI (Growth/Venture)": "10Talents XI",
          "10Talents XII (Kline Hill)": "10Talents XII",
          "10Talents XIII (DFW)": "10Talents XIII",
          "10Talents XIV (Castle Creek)": "10Talents XIV",
          "10Talents XV (Growth/Venture)": "10Talents XV",
          "VL Blue I (AEA Investors)": "VL Blue I",
          "VL Orange I (Jasper Ridge)": "VL Orange I",
          "VL Orange II (Jasper Ridge)": "VL Orange II",
        };

        const doc_type3 = docTypeMap[filters.doc_type];
        const fund2 = fundLongNameMap[filters.fund];

        const options = {
          headers: {
            Authorization: token,
          },
          queryStringParameters: {
            docType: doc_type3,
            Fund: fund2,
          },
        };

        const response = await API.get(apiName, path, options);

        const emailMap = response.reduce((acc, email) => {
          const emailKey = `${email.full_name.S}-${email.email.S}`;
          if (!acc[emailKey]) {
            acc[emailKey] = {
              fullName: email.full_name.S,
              email: email.email.S,
              reportNames: [],
            };
          }
          acc[emailKey].reportNames.push(
            email.report_name ? email.report_name.S : "N/A"
          );
          return acc;
        }, {});

        let emailList = Object.values(emailMap).map((email) => ({
          ...email,
          reportNames: email.reportNames.sort(), // Sort the report names alphabetically
        }));

        // Sort alphabetically by full name initially
        emailList = emailList.sort((a, b) =>
          a.fullName.localeCompare(b.fullName)
        );

        setEmailList(emailList);
        const uniqueEmails = [...new Set(emailList.map((item) => item.email))];
        // console.log(uniqueEmails);
        setSelectedFinalEmails(uniqueEmails);
      } catch (error) {
        console.error("Error fetching emails:", error);
      }
    };

    if (token) {
      getEmailList();
    }
  }, [token, filters]);

  const handleCheckboxChange = (email) => {
    setSelectedEmails((prevSelected) =>
      prevSelected.includes(email)
        ? prevSelected.filter((selectedEmail) => selectedEmail !== email)
        : [...prevSelected, email]
    );
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedEmails([]); // Unselect all
    } else {
      const allEmails = emailList.map((email) => email.email);
      setSelectedEmails(allEmails); // Select all
    }
    setSelectAll(!selectAll); // Toggle selectAll state
  };

  const handleExpandRow = (index) => {
    setExpandedRows((prevExpandedRows) =>
      prevExpandedRows.includes(index)
        ? prevExpandedRows.filter((i) => i !== index)
        : [...prevExpandedRows, index]
    );
  };

  const handleSort = () => {
    const sortedList = [...emailList].sort((a, b) => {
      if (sortOrder === "asc") {
        return a.fullName.localeCompare(b.fullName);
      } else {
        return b.fullName.localeCompare(a.fullName);
      }
    });
    setEmailList(sortedList);
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "20px",
          marginTop: "20px",
          color: "var(--text-color)",
        }}
      >
        <h5 style={{ color: "var(--text-color)" }}>Fund: {filters.fund}</h5>
        <h5 style={{ color: "var(--text-color)" }}>
          Document Type: {filters.doc_type}
        </h5>
      </div>
      <div className="table-container">
        <Table striped bordered hover variant="dark" className="document-list">
          <thead>
            <tr>
              <th style={{ width: "1%", verticalAlign: "middle" }}>
                <input
                  type="checkbox"
                  checked={selectAll}
                  onChange={handleSelectAll}
                />
              </th>
              <th style={{ verticalAlign: "middle" }}>Email</th>
              <th>
                Full Name{" "}
                <button
                  style={{
                    backgroundColor: "transparent",
                    border: "none",
                    color: "var(--secondary-color)",
                    fontSize: "0.8em",
                    padding: 0,
                    margin: 0,
                    cursor: "pointer",
                  }}
                  onClick={handleSort}
                >
                  <FaSort style={{ verticalAlign: "middle" }} />
                </button>
              </th>
            </tr>
          </thead>
          <tbody>
            {emailList.map((email, index) => (
              <React.Fragment key={index}>
                <tr>
                  <td align="center">
                    <input
                      type="checkbox"
                      checked={selectedEmails.includes(email.email)}
                      onChange={() => handleCheckboxChange(email.email)}
                    />
                  </td>
                  <td>{email.email}</td>
                  <td>
                    {email.fullName} ({email.reportNames.length}){" "}
                    <button
                      onClick={() => handleExpandRow(index)}
                      style={{
                        backgroundColor: "transparent",
                        border: "none",
                        color: "var(--secondary-color)",
                        cursor: "pointer",
                      }}
                    >
                      {expandedRows.includes(index) ? <FaMinus /> : <FaPlus />}
                    </button>
                    {expandedRows.includes(index) && (
                      <div style={{ fontSize: "0.8em", marginTop: "5px" }}>
                        <strong>Report Names:</strong>
                        <ul>
                          {email.reportNames.map((reportName, i) => (
                            <li key={i}>{reportName}</li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </td>
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default EmailList;
