// ClientApp.js
import { Route, Routes } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";

import HomePage from "./HomePage Investor";
import FundExplorer from "./Components/FundExplorer/FundExplorer";
import FileViewer from "./Components/File System/FileViewer";
import Settings from "./Components/Settings";
import FinancialForecasting from "./Components/Forecaster/Forecaster";

function ClientApp() {
  return (
    <div className={`d-flex custom-body`} id="wrapper">
      <div>
        <Routes>
          <Route
            path="/home"
            element={
              <PrivateRoute>
                <HomePage />
              </PrivateRoute>
            }
          />
          <Route
            path="/fund-explorer"
            element={
              <PrivateRoute>
                <FundExplorer />
              </PrivateRoute>
            }
          />
          <Route
            path="/files/view"
            element={
              <PrivateRoute>
                <FileViewer />
              </PrivateRoute>
            }
          />
          <Route
            path="/forecast"
            element={
              <PrivateRoute>
                <FinancialForecasting />
              </PrivateRoute>
            }
          />
          <Route
            path="/settings"
            element={
              <PrivateRoute>
                <Settings />
              </PrivateRoute>
            }
          />
        </Routes>
      </div>
    </div>
  );
}

export default ClientApp;
