import React, { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import PresignedUrlList2 from "./PresignedUrls2";
import BatchFileTable2 from "./BatchFileTable2";

const FileViewer = () => {
  const [filters, setFilters] = useState({
    fund: "",
    doc_type: "",
    startDate: new Date("2013-01-01"),
    token: "",
    isAdminMode: false,
    selectedSerialID: null,
  });

  useEffect(() => {
    async function getAuthToken() {
      try {
        const session = await Auth.currentSession();
        const token = session.getIdToken().getJwtToken();
        setFilters((prevFilters) => ({ ...prevFilters, token }));
        const idToken = session.getIdToken();
        const isAdminMode =
          idToken.payload["cognito:groups"].includes("Admins") ||
          idToken.payload["cognito:groups"].includes("SuperAdmins");
        setFilters((prevFilters) => ({ ...prevFilters, isAdminMode }));
      } catch (error) {
        console.log(error.message);
      }
    }

    getAuthToken();
  }, []);

  const handleSerialIDSelect = (selectedSerialID) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      selectedSerialID,
    }));
    console.log("selectedSerialID", selectedSerialID);
  };

  return (
    <div className="master-container">
      <div className="app-container">
        {filters.isAdminMode && (
          <BatchFileTable2
            fund={filters.fund}
            token={filters.token}
            doc_type={filters.doc_type}
            startDate={filters.startDate}
            endDate={filters.endDate}
            onSerialIDSelect={handleSerialIDSelect}
            serialIDArray={filters.selectedSerialID}
          />
        )}
        <div style={{ padding: "20px" }}></div>
        <PresignedUrlList2
          fund={filters.fund}
          token={filters.token}
          doc_type={filters.doc_type}
          startDate={filters.startDate}
          endDate={filters.endDate}
          serialIDArray={filters.selectedSerialID}
        />
      </div>
    </div>
  );
};

export default FileViewer;
