import React from "react";

const CapDistTemplate = ({ date, fundLongName }) => {
  const dateStr = date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  return (
    <div>
      <p>
        A{" "}
        <span style={{ fontWeight: "bold", textDecoration: "underline" }}>
          DISTRIBUTION NOTICE
        </span>{" "}
        for {fundLongName} is available for your review on our secure investor
        reporting portal.
      </p>
      <p>
        This money will be wired to the account we have on file for you as of{" "}
        <span style={{ fontWeight: "bold" }}>today, {dateStr}.</span>
      </p>
    </div>
  );
};

export default CapDistTemplate;
