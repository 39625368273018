import React from "react";

const K1Template = ({ year, fundLongName }) => {
  return (
    <div>
      <p>
        Your{" "}
        <span style={{ fontWeight: "bold" }}>
          {year.toLocaleString("en-US", { year: "numeric" })} K-1 for{" "}
          {fundLongName}{" "}
        </span>{" "}
        is available for you to review on our secure investor reporting portal.
      </p>
      <p>
        If you instructed us to allow your CPA access to your tax documents on
        the portal, please note that{" "}
        <span style={{ textDecoration: "underline" }}>
          your CPA will receive this communication as well.{" "}
        </span>
      </p>
    </div>
  );
};

export default K1Template;
