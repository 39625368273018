// Import necessary libraries
import React, { useState, useEffect } from "react";
import { Chart } from "react-chartjs-2";
import xirr from "xirr";

const FinancialForecasting = () => {
  const hardcodedData = [
    { date: new Date("2023-01-01"), value: -100000 },
    { date: new Date("2023-03-01"), value: 20000 },
    { date: new Date("2023-06-01"), value: 15000 },
    { date: new Date("2023-09-01"), value: 25000 },
    { date: new Date("2024-01-01"), value: 30000 },
  ];

  const [xirrValue, setXirrValue] = useState(null);
  const [chartData, setChartData] = useState(null);

  useEffect(() => {
    calculateXirr(hardcodedData);
  }, []);

  const calculateXirr = (cashFlows) => {
    try {
      const xirrResult = xirr(cashFlows, { guess: 0.1 });
      setXirrValue((xirrResult * 100).toFixed(2));
      prepareChartData(cashFlows);
    } catch (error) {
      console.error("Error calculating XIRR:", error);
      setXirrValue("Error calculating XIRR");
    }
  };

  const prepareChartData = (cashFlows) => {
    const sortedData = [...cashFlows].sort((a, b) => a.date - b.date);
    const cumulativeCashFlows = [];
    let cumulativeValue = 0;

    sortedData.forEach((flow) => {
      cumulativeValue += flow.value;
      cumulativeCashFlows.push({
        date: flow.date,
        value: cumulativeValue,
      });
    });

    setChartData({
      labels: cumulativeCashFlows.map(
        (d) => d.date.toISOString().split("T")[0]
      ),
      datasets: [
        {
          label: "Cumulative Cash Flow",
          data: cumulativeCashFlows.map((d) => d.value),
          borderColor: "rgba(75,192,192,1)",
          fill: false,
          borderWidth: 2,
        },
      ],
    });
  };

  return (
    <div className="master-container">
      <h1>Financial Forecasting</h1>
      {xirrValue && <h2>XIRR: {xirrValue}%</h2>}
      {chartData && (
        <Chart
          type="line"
          data={chartData}
          options={{
            responsive: true,
            plugins: {
              legend: {
                display: true,
              },
            },
            scales: {
              x: {
                title: {
                  display: true,
                  text: "Date",
                },
              },
              y: {
                title: {
                  display: true,
                  text: "Cumulative Cash Flow",
                },
              },
            },
          }}
        />
      )}
    </div>
  );
};

export default FinancialForecasting;
