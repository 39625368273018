import React, { useState } from "react";
import FormWizard from "react-form-wizard-component";
import "react-form-wizard-component/dist/style.css";
// import { Button, Row, Col } from "react-bootstrap";
import FileUpload from "./FileUpload";
import ViewMergeFiles from "./ViewMergeFiles";
import ViewStagingFiles from "./ViewStagingFiles";
// import Merge from "./Merge";
import { FaUpload, FaCheck, FaClipboardCheck } from "react-icons/fa";

const UploadWizard = () => {
  const [currentStep, setCurrentStep] = useState(0); // Tracks the current step

  // const handleNextClick = () => {
  //   console.log("Approve button clicked");
  //   setCurrentStep(1); // Navigate to the Completion step
  // };

  return (
    <div className="master-container" style={{ maxWidth: "60%" }}>
      <FormWizard activeTab={currentStep} color="var(--secondary-color)">
        {/* First Tab: Upload Files */}
        <FormWizard.TabContent title="Upload Files" icon={<FaUpload />}>
          <FileUpload />
          {/* <Button variant="success" onClick={handleNextClick}>
            Next <FaCheck />
          </Button> */}
        </FormWizard.TabContent>

        {/* Second Tab: Merge Step */}
        <FormWizard.TabContent title="Review" icon={<FaClipboardCheck />}>
          <ViewStagingFiles />
        </FormWizard.TabContent>

        {/* Third Tab: Completion */}
        <FormWizard.TabContent title="Complete" icon={<FaCheck />}>
          <h4 style={{ textAlign: "center", marginTop: "20px" }}>
            Complete! Refresh the page to start a new upload.
          </h4>
        </FormWizard.TabContent>
      </FormWizard>
    </div>
  );
};

export default UploadWizard;
