import React, { useState, useEffect } from "react";
import { API, Auth } from "aws-amplify";
import GenericTable from "../CRUD Tables/GenericTable"; // Assuming GenericTable is in the same directory
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { FaEye } from "react-icons/fa";
import DOMPurify from "dompurify";
import "bootstrap/dist/css/bootstrap.min.css";

const EmailEventLog = () => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState({});

  const apiName = "talentsapi";
  const path = "/getEmailEventLog";

  const fetchData = async () => {
    setError(null);
    try {
      const currentSession = await Auth.currentSession();
      const token = currentSession.getIdToken().getJwtToken();

      const options = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await API.get(apiName, path, options);

      const sortedData = response.sort(
        (a, b) => new Date(b.timestamp.S) - new Date(a.timestamp.S)
      );

      setData(sortedData);
    } catch (error) {
      setError(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const openPreviewModal = (item) => {
    setModalContent(item);
    setShowModal(true);
  };

  const closePreviewModal = () => {
    setShowModal(false);
    setModalContent({});
  };

  const createMarkup = (html) => {
    return { __html: DOMPurify.sanitize(html) };
  };

  const headers = [
    "ID",
    "Time Stamp",
    "Email Subject",
    "Email Body",
    "Template Content",
    "To Addresses",
    "Preview",
  ];

  const visibleColumns = [
    // "id",
    "timestamp",
    "emailSubject",
    "emailBody",
    "templateContent",
    "toAddresses",
    "preview",
  ];

  const dataForTable = data.map((item) => ({
    id: item.id.S,
    timestamp: new Date(item.timestamp.S).toLocaleString(),
    emailSubject: item.emailSubject.S,
    emailBody: <div dangerouslySetInnerHTML={createMarkup(item.emailBody.S)} />,
    templateContent: JSON.stringify(item.templateContent),
    toAddresses: item.toAddresses.S,
    preview: (
      <button onClick={() => openPreviewModal(item)}>
        <FaEye />
      </button>
    ),
  }));

  if (error) return <p>Error loading data: {error.message}</p>;

  // Define which columns should be filterable
  const filterableColumns = ["timestamp", "emailSubject"]; // Exclude presignedUrl from filters

  // Define the state of each action
  const actionStates = {
    download: false, // Allow download if URLs exist
    create: false, // Allow creation
    delete: false, // Disable delete
    update: false, // Allow update
  };

  return (
    <div className="master-container">
      <h2>Email Event Log</h2>
      <div className="app-container">
        <GenericTable
          data={dataForTable}
          headers={headers}
          keyField="id"
          visibleColumns={visibleColumns}
          onRowSelect={null} // Add your selection logic if needed
          actionStates={actionStates}
          filterableColumns={filterableColumns}
        />
      </div>

      <Modal show={showModal} onHide={closePreviewModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Email Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            <strong>Subject:</strong>{" "}
            {modalContent.emailSubject && modalContent.emailSubject.S}
          </p>
          <p>
            <strong>Body:</strong>
          </p>
          <div
            dangerouslySetInnerHTML={createMarkup(
              modalContent.emailBody ? modalContent.emailBody.S : ""
            )}
          />
          <p>
            <strong>Template Content:</strong>
          </p>
          <div
            dangerouslySetInnerHTML={createMarkup(
              modalContent.templateContent ? modalContent.templateContent.S : ""
            )}
          />
          <p>
            <strong>To Addresses:</strong>{" "}
            {modalContent.toAddresses && modalContent.toAddresses.S}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closePreviewModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default EmailEventLog;
