import React, { useState, useEffect } from "react";
import CreateModal from "../CRUD Tables/CreateModal";
import GenericTable from "../CRUD Tables/GenericTableGraphQL";
import { API } from "aws-amplify";
import { FaRedo, FaPlusSquare } from "react-icons/fa";
import { Row, Col } from "react-bootstrap";
import {
  listAllFeederFunds,
  listAllContacts,
  listAllAccounts,
  listAllInvestors,
  listAllOpportunities,
  listAllDocPackageNames,
  listAllDocPackagePermissions,
} from "../../graphql/queries";

const RecordManager = ({ selectedType, columnsMap }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  // console.log("RM selectedType", selectedType);
  // console.log("RM columnsMap", columnsMap);

  // Map selectedType values to corresponding GraphQL queries
  const queryMap = {
    "Feeder Funds": listAllFeederFunds,
    Contacts: listAllContacts,
    Accounts: listAllAccounts,
    Investors: listAllInvestors,
    Opportunities: listAllOpportunities,
    "Doc. Packages": listAllDocPackageNames,
    "Doc. Package Permissions": listAllDocPackagePermissions,
  };

  const fetchData = async () => {
    const query = queryMap[selectedType]; // Get the query based on selectedType
    // console.log("query", query);

    if (query) {
      try {
        setLoading(true);
        const response = await API.graphql({ query });

        // Dynamically access the data field based on the selected type
        const dataKey = Object.keys(response.data)[0];
        // console.log("dataKey", dataKey);
        const items = response.data[dataKey] || []; // Adjust for expected response structure
        // console.log("items", items);

        if (items.length > 0) {
          // Map items to replace IDs with names
          const processedItems = items.map((item) => {
            if (selectedType === "Contacts") {
              return {
                ...item,
                account_name: item.account ? item.account[0].account_name : "",
              };
            } else if (selectedType === "Investors") {
              return {
                ...item,
                account_name: item.account ? item.account[0].account_name : "",
              };
            } else if (selectedType === "Opportunities") {
              return {
                ...item,
                investor_name: item.investor[0]
                  ? item.investor[0].investor_name
                  : "",
                feeder_fund_name: item.feeder_fund[0]
                  ? item.feeder_fund[0].feeder_fund_name
                  : "",
              };
            } else if (selectedType === "Doc. Package Permissions") {
              return {
                ...item,
                contact_name: item.contact[0]
                  ? item.contact[0].contact_name
                  : "",
                doc_package_name: item.doc_package_name[0]
                  ? item.doc_package_name[0].doc_package_name
                  : "",
                opportunity_name: item.opportunity[0]
                  ? item.opportunity[0].opportunity_name
                  : "",
              };
            }
            // Add similar processing for other types
            return item;
          });

          // console.log("Processed data:", processedItems);
          setData(processedItems);
        } else {
          setData([]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setData([]); // Clear data on error
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [selectedType]);

  const handleOpenModal = () => {
    // console.log("handleOpenModal called");
    setIsModalOpen(true);
  };

  const handleCloseModal = () => setIsModalOpen(false);

  useEffect(() => {
    // console.log("isModalOpen state changed:", isModalOpen);
  }, [isModalOpen]);

  // Compute headers after data is set
  const headers = data.length > 0 ? Object.keys(data[0]) : [];

  // Add console logs for debugging
  // console.log("selectedType:", selectedType);
  // console.log("columnsMap:", columnsMap);
  // console.log("columnsMap[selectedType]:", columnsMap[selectedType]);

  // Get columns configuration based on selectedType
  const {
    visibleColumns,
    filterableColumns,
    updateFields,
    createFields,
    deleteFields,
  } = columnsMap[selectedType] || {
    visibleColumns: headers, // If not specified, show all columns
    filterableColumns: headers, // If not specified, make all columns filterable
    updateFields: headers, // If not specified, make all columns filterable
    createFields: headers, // If not specified, make all columns filterable
    deleteFields: headers, // If not specified, make all columns filterable
  };

  // Add console logs for debugging
  // console.log("visibleColumns:", visibleColumns);
  // console.log("filterableColumns:", filterableColumns);
  // console.log("updateFields:", updateFields);
  // console.log("createFields:", createFields);
  // console.log("deleteFields:", deleteFields);

  // Action states can be customized for each type
  const actionStates = {
    download: false,
    create: true,
    delete: true,
    update: true,
  };

  return (
    <div>
      <Row style={{ marginBottom: "10px" }}>
        <Col>
          <button onClick={fetchData}>
            <FaRedo /> Refresh
          </button>
        </Col>
        <Col>
          <button onClick={handleOpenModal}>
            <FaPlusSquare /> Add {selectedType}
          </button>
        </Col>
      </Row>
      <GenericTable
        type={selectedType}
        data={data}
        loading={loading}
        headers={headers}
        keyField="id"
        visibleColumns={columnsMap.visibleColumns}
        filterableColumns={columnsMap.filterableColumns}
        actionStates={actionStates}
        selectedType={selectedType}
        columnsMap={columnsMap}
      />
      <CreateModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        type={selectedType}
        columnsMap={columnsMap}
      />
    </div>
  );
};

export default RecordManager;
