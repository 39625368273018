import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./FundExplorer.css";
import dummyData from "./dummyData";
import {
  FaApple,
  FaDna,
  FaChartLine,
  FaSolarPanel,
  FaShoppingCart,
  FaCogs,
  FaChartBar,
  FaTable,
} from "react-icons/fa";
import { Bar } from "react-chartjs-2";
import "chart.js/auto";

const iconMap = {
  FaApple: FaApple,
  FaDna: FaDna,
  FaChartLine: FaChartLine,
  FaSolarPanel: FaSolarPanel,
  FaShoppingCart: FaShoppingCart,
  FaCogs: FaCogs,
};

const Tile = ({ data, onClick }) => (
  <div className="tile" onClick={() => onClick(data)}>
    {React.createElement(iconMap[data.icon], { size: 50 })}
    <p className="tile-name">{data.name}</p>
  </div>
);

const PerformanceTable = ({ performance }) => (
  <table className="performance-table">
    <thead>
      <tr>
        <th>Name</th>
        <th>Vintage</th>
        <th>IRR</th>
        <th>MOI</th>
      </tr>
    </thead>
    <tbody>
      {performance.map((item, index) => (
        <tr key={index}>
          <td>{item.name}</td>
          <td>{item.vintage}</td>
          <td>{item.irr}</td>
          <td>{item.moi}</td>
        </tr>
      ))}
    </tbody>
  </table>
);

const PerformanceChart = ({ performance }) => {
  const chartData = {
    labels: performance.map((item) => item.name),
    datasets: [
      {
        label: "IRR",
        data: performance.map((item) => parseFloat(item.irr.replace("%", ""))),
        backgroundColor: "#4CAF50",
        borderRadius: 5, // Rounded corners
      },
      {
        label: "MOI",
        data: performance.map((item) => parseFloat(item.moi.replace("x", ""))),
        backgroundColor: "#2196F3",
        borderRadius: 5, // Rounded corners
      },
    ],
  };

  const chartOptions = {
    plugins: {
      legend: {
        display: true,
        position: "top",
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          color: "#ddd",
        },
        beginAtZero: true,
      },
    },
  };

  return <Bar data={chartData} options={chartOptions} />;
};

const FundExplorer = () => {
  const [selectedData, setSelectedData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [viewMode, setViewMode] = useState("table");

  const handleTileClick = (data) => {
    setSelectedData(data);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedData(null);
  };

  return (
    <div className="master-container">
      <div className="tile-container">
        {dummyData.map((item) => (
          <Tile key={item.id} data={item} onClick={handleTileClick} />
        ))}
      </div>

      {selectedData && (
        <Modal show={showModal} onHide={handleCloseModal} centered>
          <Modal.Header closeButton>
            <div style={{ textAlign: "center", width: "100%" }}>
              {React.createElement(iconMap[selectedData.icon], {
                size: 60,
                style: { marginBottom: "10px" },
              })}
              <Modal.Title>{selectedData.longName}</Modal.Title>
            </div>
          </Modal.Header>
          <Modal.Body style={{ textAlign: "left" }}>
            <p>
              <strong>Sector:</strong> {selectedData.sector}
            </p>
            <p>
              <strong>Industry:</strong> {selectedData.industry}
            </p>
            <p>
              <strong>Description:</strong> {selectedData.description}
            </p>
            <p>
              <strong>IAC Memo:</strong>{" "}
              <p style={{ textDecoration: "underline" }}>{selectedData.memo}</p>
            </p>
            <div className="performance-section">
              <div className="performance-header">
                <h3>Past Performance</h3>
                <div className="toggle-icons">
                  <FaTable
                    size={24}
                    className={viewMode === "table" ? "active-icon" : "icon"}
                    onClick={() => setViewMode("table")}
                  />{" "}
                  <FaChartBar
                    size={24}
                    className={viewMode === "chart" ? "active-icon" : "icon"}
                    onClick={() => setViewMode("chart")}
                  />
                </div>
              </div>
              {viewMode === "table" ? (
                <PerformanceTable performance={selectedData.pastPerformance} />
              ) : (
                <PerformanceChart performance={selectedData.pastPerformance} />
              )}
            </div>
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
};

export default FundExplorer;
