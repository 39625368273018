// RibbonBar.jsx
import React, { useState, useEffect, useRef } from "react";
import { FaCaretDown } from "react-icons/fa";
import "./RibbonBar.css";

const RibbonBar = ({ options, selectedOption, onOptionClick }) => {
  const [visibleOptions, setVisibleOptions] = useState(options);
  const [overflowOptions, setOverflowOptions] = useState([]);
  const ribbonRef = useRef(null);

  const calculateVisibleOptions = () => {
    if (ribbonRef.current) {
      const containerWidth = ribbonRef.current.offsetWidth;
      const children = Array.from(ribbonRef.current.children);
      let totalWidth = 0;
      let lastVisibleIndex = children.length - 1;

      for (let i = 0; i < children.length; i++) {
        const child = children[i];
        totalWidth += child.offsetWidth;

        if (totalWidth > containerWidth) {
          lastVisibleIndex = i - 1;
          break;
        }
      }

      const newVisibleOptions = options.slice(0, lastVisibleIndex + 1);
      const newOverflowOptions = options.slice(lastVisibleIndex + 1);

      setVisibleOptions(newVisibleOptions);
      setOverflowOptions(newOverflowOptions);
    }
  };

  useEffect(() => {
    calculateVisibleOptions(); // Initial calculation
    window.addEventListener("resize", calculateVisibleOptions); // Recalculate on resize
    return () => window.removeEventListener("resize", calculateVisibleOptions);
  }, [options]);

  return (
    <div className="ribbon-bar" ref={ribbonRef}>
      {visibleOptions.map((option) => (
        <button
          key={option}
          className={`ribbon-button ${
            option === selectedOption ? "selected" : ""
          }`}
          onClick={() => onOptionClick(option)}
        >
          {option}
        </button>
      ))}
      {overflowOptions.length > 0 && (
        <div className="ribbon-more">
          <button className="ribbon-button">
            More <FaCaretDown />
          </button>
          <div className="ribbon-more-dropdown">
            {overflowOptions.map((option) => (
              <button
                key={option}
                className={`ribbon-button ${
                  option === selectedOption ? "selected" : ""
                }`}
                onClick={() => onOptionClick(option)}
              >
                {option}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default RibbonBar;
